import React, { useState, useCallback, useEffect, useRef, Fragment, useLayoutEffect } from 'react';
import { batch, connect, useSelector } from 'react-redux';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import PropTypes, { object, shape } from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import { Icon } from '@mdi/react';
import EditIcon from '@mui/icons-material/Edit';
import MUISelect from '@material-ui/core/Select';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { Formik, Form, FieldArray, getIn } from 'formik';
import CustomExpansionPanel from './ExpansionPanel';
import DateTimePicker from 'components/FormUI/DateTimePicker';
import moment from 'moment';
import WeekdayPicker from './WeekdayPicker';
import TimePickerField from 'components/FormUI/TimePickerField';
import 'components/FormUI/TimePickerField.scss';
import { mdiPlus, mdiMinusCircleOutline } from '@mdi/js';
import Checkbox from '@material-ui/core/Checkbox';
import DatePicker from 'react-multi-date-picker';
import { Calendar } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { DATE_FORMATS } from 'utils/datesAndMoney';
import { Field } from 'formik';
import { BsDash } from 'react-icons/bs';
import { Switch, StyledSlider, StyledInput, SlimSwitch, SlimStyledSlider } from 'components/switch/style';
import LiveSessionReminders from './MembershipForm/components/LiveSessionReminders';
import {
  useTheme,
  useMediaQuery,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  SvgIcon,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { enrollmentFormSchema } from '../contributionValidations';
import Select from 'components/FormUI/Select';
import Input from 'components/FormUI/Input';
import Button from 'components/FormUI/Button';
import MUIButton from '@mui/material/Button';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { toRem, colors } from 'utils/styles';
import { TIMEZONES, LiveVideoProvider, PhoneCallOptions } from 'constants.js';
import { useShallowEqualSelector, useHttp, useRouter, useDefaultTimezone } from 'hooks';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { getDomainIcon } from 'utils/calendar';
import AddCalendarModal from 'components/Modals/AddCalendarModal/AddCalendarModal';
import { RemoveCalendarModal } from 'components/Modals/AddCalendarModal/RemoveCalendar';
import LiveVideo from './MembershipForm/components/LiveVideo';
import * as calendarsActions from 'actions/calendars';
import * as contributionActions from 'actions/contributions';
import CloseIcon from '@material-ui/icons/Close';
import CustomCalendar from './Calendar';
import Session from './Session';
import { cloneDeep, isArray, isInteger, isNumber, isUndefined, uniqBy } from 'lodash';
import TextArea from 'components/FormUI/TextArea';
import * as oneToOneDataActions from 'actions/oneToOneData';
import CustomQuestions from './CustomQuestions';
import { TOOLTIP } from '../../../constants.js';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/build/index.css';
import './BlockdaysCalendar.scss';
import AddIcon from '@mui/icons-material/Add';
import DateNavigationComponent from 'pages/MasterCalendarPage/components/DateNavigator';
import MasterCalendarPage from 'pages/MasterCalendarPage/MasterCalendarPage';
import { useDispatch } from 'react-redux';
import * as calendarAction from 'actions/calendars';
import { AvailabilityModal } from 'pages/MasterCalendarPage/components/availabilityModal';
import SettingsIcon from '@mui/icons-material/Settings';
import ExternalCalendarSettingsPopup from '../../MasterCalendarPage/components/ExternalCalendarSettingsPopup';
import SendInvitesFromModal from 'components/Modals/AddCalendarModal/SendInvitesFromModal';
import GoogleIcon from '@mui/icons-material/Google';
import { ZoomDefaultProviderModal } from 'components/Modals/ZoomDefaultProviderModal';
import { ContributionType } from 'helpers/constants';
import { whitelist } from 'validator';
import { hover } from 'reactcss';
import Modal from 'components/UI/Modal';
import { getOneOnOneContributons } from 'services/contributions.service';
import { StyledInputSwitch, StyledSwitch, StyledToggleSwitch } from '../ReviewAndSubmit';

const StyledTimeZoneLabel = styled.p`
  display: inline-block;
  font-size: 13px;
  font-family: Avenir;
  background: #116582;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
  color: white;
  font-weight: 500;
  line-height: 19.5px;

  ${({ mobileView }) =>
    mobileView &&
    `
   margin-left: 0px;
    font-size: 13px;
  `}
`;
const StyledClientBook = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1;
  margin-bottom: 0px;
`;
const StyledCheckbox = styled(Checkbox)`
  padding-left: 0 !important;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 -5px;
`;

const StyledBsDash = styled(BsDash)``;

const StyledLink = styled.span`
  max-width: 240px;
  display: flex;
  font-family: Avenir;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: ${colors.darkOceanBlue};
  cursor: pointer;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  justify-content: center;
  padding: 2px 0px;

  &:hover {
    text-decoration: underline;
  }
`;
const useStyles = makeStyles(() => ({
  color_secondary: {
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10px',
    marginBottom: '15px',
  },
  heading: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: '0.1px',
  },
  toggle_btn: {
    marginTop: '3px',
  },
  textfield_input_root: {
    minHeight: '44px',
  },
}));
const StyledButton = styled(Button)`
  margin: ${({ mobileView }) => (mobileView ? '10px 0' : '0 10px')};
  min-width: ${({ mobileView }) => (mobileView ? '150px' : '180px')};
  margin-left: ${({ mobileView }) => (mobileView ? '5px' : '')};
  padding-top: ${({ mobileView }) => (mobileView ? '16px' : '16px')};
  padding-bottom: ${({ mobileView }) => (mobileView ? '16px' : '16px')};
  ${({ mobileView }) =>
    mobileView
      ? css``
      : css`
          padding: 1rem 1.25rem;
          height: 2.5rem;
        `};
  color: #fff;
  text-align: center;
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1rem; /* 114.286% */
  text-transform: capitalize;
  display: flex;
  align-items: center;
`;

const StyledLabel = styled.div`
  width: 100px;
  font-family: 'Brandon Text';
`;

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
  justify-content: space-between;
  align-items: ${({ ForEditSessionsHeader, mobileView }) =>
    !ForEditSessionsHeader ? '' : mobileView ? 'baseline' : 'center'};
  gap: ${({ ForEditSessionsHeader }) => ForEditSessionsHeader && '10px'};
`;
const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledFullWidthFlexContainer = styled(StyledFlexContainer)`
  width: fit-content;
  max-width: fit-content;
  min-width: '320px';
  ${({ mobileView }) =>
    mobileView &&
    `
   justify-content: space-between;
  `}
`;
const StyledCalendarTabContainer = styled(StyledFullWidthFlexContainer)`
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
  align-items: ${({ mobileView }) => (mobileView ? 'flex-end' : 'center')};
  justify-content: flex-end;
  flex-wrap: ${({ wrapCalendars }) => wrapCalendars && 'wrap-reverse'};
  ${({ ForEditSessionsHeader }) =>
    ForEditSessionsHeader &&
    `
      flex-direction : row;
      align-items: center;
      gap: 10px;
  `}/* overflow: hidden; */
`;
const StyledCalendar = styled(StyledFlexContainer)`
  width: ${({ mobileView }) => mobileView && '120px'};
  justify-content: center;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 6px;
  margin-right: 2px;
  min-height: 40px;
  svg.settings-icon {
    cursor: pointer;
  }
  svg {
    color: #4a4a4a;
    cursor: pointer;
    margin: auto;
  }
  /* svg:not(.provider-logo) {
    margin-left: 0.5rem;
  } */
  svg.provider-logo {
    cursor: default;
    width: 0.9375rem;
    height: 0.9375rem;
    /* margin-right: 0.5rem; */
  }
`;

const StyledDivContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ smView }) => (smView ? 'column' : 'row')};
  margin-bottom: ${({ smView }) => (smView ? '16px' : '0')};
  justify-content: space-between;
  align-items: center;
`;

const StyledCalendarsContainer = styled(StyledFlexContainer)`
  width: ${({ mobileView, ForEditSessionsHeader }) => (mobileView && !ForEditSessionsHeader ? '290px' : 'fit-content')};
  justify-content: ${({ onlyChild, mobileView, ForEditSessionsHeader }) =>
    ForEditSessionsHeader ? 'flex-end' : mobileView ? 'flex-start' : onlyChild ? 'flex-end' : 'flex-end'};
  overflow-x: auto;
  padding: ${({ mobileView }) => (mobileView ? '10px 0' : '10px 5px')};
  /* margin-left: ${({ mobileView }) => (mobileView ? '0' : '10px')}; */
  gap: 0.5rem;
  /* max-width: ${({ mobileView }) => (mobileView ? '290px' : '500px')}; */
`;

const StyledEmail = styled.span`
  white-space: nowrap;
  // width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;
const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const initialPhoneOptions = [PhoneCallOptions.coachPhone];
export const chooseProviderIcon = mailProvider => {
  switch (mailProvider) {
    case 'gmail':
      return GoogleIcon;
    default:
      return null;
  }
};
const durationsOneToOne = [
  { title: '15 minutes', value: 15 },
  { title: '20 minutes', value: 20 },
  { title: '30 minutes', value: 30 },
  { title: '45 minutes', value: 45 },
  { title: '1 hour', value: 60 },
  { title: '90 minutes', value: 90 },
  { title: '2 hours', value: 120 },
  { title: '3 hours', value: 180 },
  { title: '4 hours', value: 240 },
];

export const startIncrementDurationsOneToOne = [
  { title: 'Default Increment', value: 0 },
  { title: '15 min', value: 15 },
  { title: '30 min', value: 30 },
  { title: '1 hour', value: 60 },
];

const durationValues = {
  tenDays: 10,
  thirtyDays: 30,
  sixtyDays: 60,
  ninetyDays: 90,
  customDaysValue: 'custom',
};

const defaultQuestionValue = {
  name: '',
  isOptional: false,
  questionType: 'Radio',
  options: [],
};

const bufferTimeValues = [
  { title: '15 min', value: 15 },
  { title: '30 min', value: 30 },
  { title: '1 hour', value: 60 },
];

const durationUnitsValues = [
  { title: 'Hours', value: 'Hours' },
  { title: 'Days ', value: 'Days' },
];

const dateRangeOneToOne = [
  { title: '10 days', value: durationValues.tenDays },
  { title: '30 days', value: durationValues.thirtyDays },
  { title: '60 days', value: durationValues.sixtyDays },
  { title: '90 days', value: durationValues.ninetyDays },
  { title: 'Custom', value: durationValues.customDaysValue },
];

const CustomRadio = styled(Radio)`
  .MuiIconButton-label {
    color: #213649;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  width: ${({ mobileView }) => (mobileView ? '100%' : '60%')};
  gap: 10px;
  &.phone-error {
    .react-international-phone-input {
      border-color: red;
    }
  }
  .react-international-phone-country-selector {
    .react-international-phone-country-selector-button {
      padding: 10px 6px;
      height: auto;
      border-radius: 10px;
    }
  }
  .react-international-phone-input {
    width: 100%;
    padding: 10px 10px;
    height: auto;
    border-radius: 10px;
    font-size: 14px;
  }
`;

export const SelectStyled = styled(Select)`
  .MuiSelect-select {
    padding: 7px 12px;
  }
`;

const AutoCompleteText = styled.span`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  line-height: 19.5px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ mobileView }) => (mobileView ? '100%' : '275px')};
`;

const viewTabButtonsData = ['Day', 'Week', 'Month'];
const orderMap = { M: 1, T: 2, W: 3, Th: 4, F: 5, S: 6, Su: 7 };

const OneToOneFormComponent = ({
  values,
  oneToOneData,
  otherEvents,
  alreadyBooked,
  calendars,
  fetchCalendars,
  addCalendar,
  accountsForConflictsCheck,
  getAcсountsForConflictsCheck,
  getAcсountForSendReminders,
  contribution,
  removeCalendarFromConflictsCheck,
  removeCalendar,
  lastAddedMail,
  loadingLastEmail,
  getEditContributionTimes,
  loadingTimes,
  calculateSlots,
  setFieldValue,
  setSessionDuration,
  sessionDuration,
  sessionDurationError,
  setStartTimeIncrementDuration,
  startTimeIncrementDuration,
  startTimeIncrementSlots,
  startTimeIncrementDurationError,
  setSelectedEmailCalendar,
  selectedEmailCalendar,
  setAddCustomEnrollmentQuestions,
  customQuestionsFormRef,
  customEnrollmentFormRef,
  errors,
  touched,
  CustomInvitationBodyDefault,
  customInvitaionBodyText,
  oneToDateRange,
  setOneToDateRange,
  blockedDates,
  selectedWeeks,
  oneToOneEndDate,
  oneToOneStartDate,
  setStartDate,
  setEndDate,
  setOneToOneTime,
  removeBookingTime,
  addBookingTime,
  setBlockedDates,
  calendarActiveView,
  smsReminderSettings,
  setExternalCalendarSetToDefault = () => {},
  externalCalendarSetToDefault,
}) => {
  const initialLiveVideProviders = [
    LiveVideoProvider.Cohere,
    LiveVideoProvider.Custom,
    // LiveVideoProvider.InPersonSession,
  ];
  const [showAvailabilityPopUp, setshowAvailabilityPopUp] = useState(false);
  const [minNoticeExpand, setMinNoticeExpand] = useState(false);
  const isZoomSelected = values?.liveVideoServiceProvider?.providerName === 'Zoom';
  const [newSlots, setNewSlots] = useState(0);
  const [isExpansionDisabled, setIsExpansionDisabled] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const classes = useStyles();
  const [showTerms, setShowTerms] = useState(false);
  const firstSelectedWeek = selectedWeeks[0] || [];
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const firstSelectedWeekStartTime = moment.utc(selectedWeeks[0]?.startTime).format('LT').toLowerCase();
  const firstSelectedWeekEndTime = moment.utc(selectedWeeks[0]?.endTime).format('LT').toLowerCase();
  const firstSelectedWeekDays = selectedWeeks[0]?.days || [];
  const firstSelectedWeekDaysArray = firstSelectedWeekDays.map((item, index) => item.title);
  const firstSelectedWeekDaysArraySorted = firstSelectedWeekDaysArray.sort((a, b) => orderMap[a] - orderMap[b]);
  const joinedSelectedWeekDays =
    firstSelectedWeekDaysArraySorted.length > 1
      ? `${firstSelectedWeekDaysArraySorted.slice(0, -1).join(', ')} and ${
          firstSelectedWeekDaysArraySorted[firstSelectedWeekDaysArraySorted.length - 1]
        }`
      : firstSelectedWeekDaysArraySorted[0];
  const [openApplySettingsModal, set0penApplySettingsModal] = useState(false);
  const { bufferTimeBefore, bufferTimeAfter } = values;
  const calenderRef = useRef();
  const [currentCalendarView, setCurrentCalendarView] = useState(calendarActiveView);
  const [newBlockedDates, setNewBlockedDates] = useState([]);
  const [daysDiff, setDaysDiff] = useState(moment(oneToOneEndDate).diff(moment(oneToOneStartDate), 'days'));

  const [isBlockSingleDayExpanded, setIsBlockSingleDayExpanded] = useState(false);
  const [isSchedulingDetailExpanded, setIsSchedulingDetailExpanded] = useState(true);
  const dispatch = useDispatch();
  const [contributionList, setContributionList] = useState([{ id: 1, title: 'Contribution 1' }]);
  const [allcontributionList, setAllContributionList] = useState([]);
  const [liveVideoProviders, setLiveVideoProviders] = useState(initialLiveVideProviders);
  const [phoneCallOptions, setPhoneCallOptions] = useState(initialPhoneOptions);
  const [connectionOption, setConnectionOption] = useState('');
  const [showExternalCalendarSettingsModal, setShowExternalCalendarSettingsModal] = useState(false);
  const [arrayForModal, setArrayForModal] = useState([]);
  const [selectedArrayForModal, setSelectedArrayForModal] = useState([]);
  const phoneInputRef = useRef(null);

  const filterContribution = (arr1, arr2) => {
    setArrayForModal(arr1.filter(obj => arr2.includes(obj.value)));
    setSelectedArrayForModal(arr1.filter(obj => arr2.includes(obj.value)));
  };
  const togglePopup = () => {
    set0penApplySettingsModal(!openApplySettingsModal);
    setFieldValue('copySchedulingSettings', false);
    setFieldValue('selectedContributionIdForCopySettings', []);
  };
  const [
    showExternalCalendarConnectionCompleteStepsModal,
    setShowExternalCalendarConnectionCompleteStepsModal,
  ] = useState({
    showStep1: false,
    showStep2: false,
  });
  const [calendarForSettingsPopup, setCalendarForSettingsPopup] = useState(false);
  const connectionMap = {
    custom: 'inPerson',
    Custom: 'privateLink',
    Zoom: 'zoomVideo',
    Cohere: 'cohereVideo',
  };

  const Mapper = {
    Custom: 'Private Link',
    Zoom: 'Zoom',
    Cohere: 'Cohere Live Video',
  };

  const bookingval = oneToDateRange === 'custom' ? 'within-the-range' : 'into-the-future';
  const [bookingRange, setBookingRange] = useState(bookingval);
  const [phoneNumberOption, setPhoneNumberOption] = useState('');
  const user = useShallowEqualSelector(state => state?.account?.user);
  const [isAddCalendarModalOpen, setIsAddCalendarModalOpen] = useState(false);
  const [removedCalendarEmail, setRemovedCalendarEmail] = useState(false);
  const { query, pathname } = useRouter();
  const [isRemoveEmailCalendar, setIsRemoveEmailCalendar] = useState(false);
  const addCustomEnrollmentQuestions = values?.enrollmentForm?.isCustomEnrollmentFormAdded;
  const [isBeforeBufferActive, setIsBeforeBufferActive] = useState(bufferTimeBefore === 0 ? false : true);
  const [isAfterBufferActive, setIsAfterBufferActive] = useState(bufferTimeAfter === 0 ? false : true);
  const [emailReminder, setEmailReminder] = useState(false);
  const [smsReminder, setSmsReminder] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);

  const [isMounted, setMounted] = useState(false);
  const [isSendInvitesFromModalOpen, setIsSendInvitesFromModalOpen] = useState(false);
  const [coachPhoneNumber, setCoachPhoneNumber] = useState('');
  const [coachPhoneNumberFocused, setCoachPhoneNumberFocused] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState('');
  const { fetchUserCountryCodeByIp } = useDefaultTimezone();
  const { timeZones } = useSelector(state => state.timeZone);
  const { countries } = useSelector(state => state?.country);
  const { minimumNotice } = values;

  // useEffect(() => {
  //   if (oneToDateRange === 'custom') {
  //     return;
  //   }
  //   setStartDate(moment(new Date()));
  //   setEndDate(moment(new Date()).add(oneToDateRange, 'days'));
  // }, [oneToDateRange]);
  let array = [];
  function addObjectIfNotExists(arr, newObj) {
    array = [...selectedArrayForModal];
    const idExists = selectedArrayForModal.some(obj => obj.value === newObj.value);
    if (!idExists) {
      array.push(newObj);
    }
    setSelectedArrayForModal(array);
    return arr;
  }
  useEffect(() => {
    setTimeout(() => {
      const eventDetailsSession = document.getElementById('event-details');
      if (eventDetailsSession) {
        eventDetailsSession.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 2000);
    getOnetoOneContributionsList();
  }, []);

  useEffect(() => {
    const start = moment(oneToOneStartDate).format('MM DD YYYY');
    const end = moment(oneToOneEndDate).format('MM DD YYYY');
    let diff = moment(oneToOneEndDate).diff(moment(oneToOneStartDate), 'days');
    // if (blockedDates && blockedDates.length > 0) {
    //   diff = diff - blockedDates.length;
    // }
    setDaysDiff(diff);
  }, [oneToOneEndDate, oneToOneStartDate, blockedDates]);

  const getOnetoOneContributionsList = async () => {
    await getOneOnOneContributons().then(res => {
      let updatedList = res.map(d => {
        return {
          title: d.name,
          id: d.id,
          value: d.id,
        };
      });
      let allContributions = [];
      let allList = res.map(d => {
        return allContributions.push(d.id);
      });
      setAllContributionList(allContributions);
      setContributionList(updatedList);
    });
  };
  const incrementNewSlots = () => {
    setNewSlots(preState => preState + 1);
  };
  const ifAllAreConsecutiveDays = sortedDaysArray => {
    if (sortedDaysArray.length === 1) {
      return false;
    }

    let allConsecutive = true;

    for (let i = 0; i < sortedDaysArray.length - 1; i++) {
      const currentDay = sortedDaysArray[i];
      const nextDay = sortedDaysArray[i + 1];

      if (orderMap[nextDay] - orderMap[currentDay] !== 1) {
        allConsecutive = false;
        break;
      }
    }

    return allConsecutive;
  };

  const handleCloseSendInvitesFromModalOpen = () => {
    setIsSendInvitesFromModalOpen(false);
  };
  const handleOpenSendInvitesFromModalOpen = e => {
    e.preventDefault();
    setIsSendInvitesFromModalOpen(true);
  };
  const giveTitle = (bufferTimeBefore, bufferTimeAfter) => {
    if (bufferTimeBefore > 0 && bufferTimeAfter > 0) {
      return `${bufferTimeBefore} min Before, ${bufferTimeAfter} min After`;
    } else if (bufferTimeBefore > 0) {
      return `${bufferTimeBefore} min Before`;
    } else if (bufferTimeAfter > 0) {
      return `${bufferTimeAfter} min After`;
    }
  };

  useEffect(() => {
    setAddCustomEnrollmentQuestions(addCustomEnrollmentQuestions);
  }, [addCustomEnrollmentQuestions]);

  useEffect(() => {
    if (calenderRef?.current) {
      calenderRef.current.openCalendar();
    }
  }, [calenderRef?.current]);
  useEffect(() => {
    if (blockedDates?.length > 0) {
      setNewBlockedDates(
        blockedDates?.map(date => {
          return new Date(date);
        }),
      );
    }
  }, [blockedDates]);

  useEffect(() => {
    const diff = moment(oneToOneEndDate).diff(moment(oneToOneStartDate), 'days');
    if (
      !(
        diff === durationValues.tenDays ||
        diff === durationValues.thirtyDays ||
        diff === durationValues.sixtyDays ||
        diff === durationValues.ninetyDays
      )
    ) {
      setOneToDateRange(durationValues.customDaysValue);
    }
  }, [oneToOneStartDate, oneToOneEndDate]);

  // const isCreate = pathname === '/create-contribution/sessions';
  const isCreate = false;
  const location = useLocation();

  const handleModalSubmit = useCallback(async () => {
    setIsAddCalendarModalOpen(false);
    setFieldValue('isExternalCalendarDisconnected', false);
  }, []);

  const handleOpenAddCalendarModal = e => {
    e.preventDefault();
    return isArray(calendars) && calendars.length
      ? setIsAddCalendarModalOpen(true)
      : addCalendar({ contributionId: null, isCreate: true });
  };

  const handleCloseAddCalendarModal = () => {
    setIsAddCalendarModalOpen(false);
  };

  const onRemoveCalendar = email => {
    setRemovedCalendarEmail(email);
    setIsRemoved(true);
  };

  const openAndScrollToBlockCalendar = () => {
    setIsSchedulingDetailExpanded(true);
    setIsBlockSingleDayExpanded(true);
    setTimeout(() => {
      const element = document.getElementById('blockSingleDayDiv');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 3000);
  };
  const onRemoveSelectedEmailCalendar = () => {
    setFieldValue('externalCalendarEmail', null);
    setFieldValue('isExternalCalendarDisconnected', false);
    setSelectedEmailCalendar(null);
  };

  const onRemovecalendarSubmit = () => {
    removeCalendarFromConflictsCheck(removedCalendarEmail).then(() => {
      fetchCalendars();
    });
    setFieldValue('isExternalCalendarDisconnected', false);
    setRemovedCalendarEmail(null);
  };

  const handleCloseRemoveCalendarModal = () => {
    setRemovedCalendarEmail(null);
  };

  const onChangeSessionDuration = e => {
    const { value: newDuration } = e.target;

    const incrementsLesserOrEqualIncrements = startIncrementDurationsOneToOne.filter(k => k.value <= newDuration);
    batch(() => {
      if (incrementsLesserOrEqualIncrements && incrementsLesserOrEqualIncrements.length) {
        setStartTimeIncrementDuration(incrementsLesserOrEqualIncrements?.at(0)?.value || 0);
        // if ([15, 60].includes(newDuration)) {
        //   setStartTimeIncrementDuration(newDuration);
        // } else if ([20, 30].includes(newDuration)) {
        //   setStartTimeIncrementDuration(30);
        // } else {
        //   setStartTimeIncrementDuration(60);
        // }
      }
      setSessionDuration(newDuration);
    });
  };

  const onChangeStartTimeIncrementDuration = e => {
    const { value } = e.target;

    // sessionDuration
    // const increments = [];
    // for (let i = 0; i < Math.ceil(sessionDuration / value); i++) {
    //   increments.push(i * value);
    // }
    setStartTimeIncrementDuration(value);
  };

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const mobileViewXs = useMediaQuery('(max-width:612px)');
  const wrapCalendars = useMediaQuery('(max-width:410px)');
  const smView = useMediaQuery(theme.breakpoints.down('sm'));
  const isEditMode = Boolean(query?.id);

  const history = useHistory();

  useEffect(() => {
    if (user?.isZoomEnabled) {
      setLiveVideoProviders(prevState => [...prevState, LiveVideoProvider.Zoom]);
    }
  }, [user.isZoomEnabled]);

  useEffect(() => {
    fetchCalendars();
    getAcсountsForConflictsCheck();
    getAcсountForSendReminders();
    if (location.state?.calendarModalOpen) {
      setIsAddCalendarModalOpen(true);
      const state = { ...history.location.state };
      delete state.calendarModalOpen;
      history.replace({ ...history.location, state });
    }
    setMounted(true);
    // const incrementsLesserOrEqualIncrements = startIncrementDurationsOneToOne.filter(
    //   k => k.value <= (sessionDuration || 60),
    // );
    // if (incrementsLesserOrEqualIncrements && incrementsLesserOrEqualIncrements.length) {
    //   setStartTimeIncrementDuration(incrementsLesserOrEqualIncrements?.at(-1)?.value);
    // }
  }, []);

  useLayoutEffect(() => {
    // Edit mode: contribution?.oneToOneSessionDataUi is not null
    if (contribution?.oneToOneSessionDataUi) {
      console.log('oneToOneSessionDataUi -->>', minimumNotice);
      const { oneToOneSessionDataUi, id, sessionIncrements } = contribution;
      let data;
      if (minimumNotice.value == 0 || minimumNotice.value == '0') {
        data = {
          ...oneToOneSessionDataUi,
          // ...oneToOneData,
          selectedWeeks: oneToOneData?.selectedWeeks,
          bufferTimeBefore,
          bufferTimeAfter,
          // duration: sessionDuration?.toString(),
          // duration: oneToOneSessionDataUi?.sessionDuration?.toString(),
          // sessionDuration: sessionDuration,
          sessionDuration,
          // sessionDuration: oneToOneData?.sessionDuration
        };
      } else {
        data = {
          ...oneToOneSessionDataUi,
          // ...oneToOneData,
          selectedWeeks: oneToOneData?.selectedWeeks,
          bufferTimeBefore,
          bufferTimeAfter,
          // duration: sessionDuration?.toString(),
          // duration: oneToOneSessionDataUi?.sessionDuration?.toString(),
          // sessionDuration: sessionDuration,
          sessionDuration,
          // sessionDuration: oneToOneData?.sessionDuration
          minimumNotice,
        };
      }

      if (id) {
        console.log('minimumNotice data --->', data);
        getEditContributionTimes(
          id,
          data,
          {
            ...(!isUndefined(startTimeIncrementDuration) &&
              isInteger(startTimeIncrementDuration) && { offset: startTimeIncrementDuration }),
          },
          // contribution?.durations?.[0],
          sessionDuration,
        );
      } else {
        calculateSlots(oneToOneSessionDataUi);
      }
    }
    // Create mode: oneToOneData is not null, as current settings in it before user saved
    else if (oneToOneData) {
      const { id } = contribution;
      const data = {
        ...oneToOneData,
        bufferTimeBefore,
        bufferTimeAfter,
        duration: oneToOneData?.sessionDuration?.toString(),
        minimumNotice: minimumNotice,
        // duration: oneToOneSessionDataUi?.sessionDuration?.toString(),
        // sessionDuration: sessionDuration,
        // sessionDuration,
        // sessionDuration: oneToOneData?.sessionDuration
      };
      if (id) {
        getEditContributionTimes(
          id,
          data,
          {
            ...(!isUndefined(startTimeIncrementDuration) &&
              isInteger(startTimeIncrementDuration) && { offset: startTimeIncrementDuration }),
          },
          // contribution?.durations?.[0],
          sessionDuration,
        );
      }
    }
  }, [
    getEditContributionTimes,
    contribution?.oneToOneSessionDataUi,
    oneToOneData?.selectedWeeks,
    contribution?.id,
    startTimeIncrementDuration,
    sessionDuration,
    calendars,
  ]);

  const initCallOptions = () => {
    let addClientPhoneOption = true;
    if (customQuestionsFormRef.current?.values?.enrollmentForm?.enrollmentFormViewModel?.questions?.length > 0) {
      const phoneNoQuestions = customQuestionsFormRef.current?.values?.enrollmentForm?.enrollmentFormViewModel?.questions?.filter(
        question => question.questionType === 'PhoneNo' && !question?.isPhoneNumberAdded,
      );

      if (phoneNoQuestions?.length > 0) {
        addClientPhoneOption = false;
      }
    }

    let callOptions = [...phoneCallOptions];
    callOptions = callOptions.filter(option => option.value !== PhoneCallOptions.clientsPhone.value);
    if (addClientPhoneOption) {
      callOptions = [PhoneCallOptions.clientsPhone, ...callOptions];
    }
    setPhoneCallOptions(callOptions);

    if (!values.isPhoneNumberAdded) {
      // setConnectionOption('live-video');
      let con;
      if (values?.liveVideoServiceProvider?.providerName === 'custom') {
        con = 'in-person';
      } else {
        con = 'live-video';
      }
      setConnectionOption(con);
      setFieldValue(
        'customInvitationBody',
        customInvitaionBodyText[connectionMap[values?.liveVideoServiceProvider?.providerName]],
      );
      // setFieldValue(
      //   'customInvitationBody',
      //   contribution?.customInvitationBody && contribution.status === 'Approved'
      //     ? contribution.customInvitationBody
      //     : customInvitaionBodyText[connectionMap[contribution?.liveVideoServiceProvider?.providerName]],
      // );
    } else {
      setConnectionOption('phone-call');
      setFieldValue('customInvitationBody', customInvitaionBodyText.phoneCall);
      setPhoneNumberOption(
        addClientPhoneOption ? PhoneCallOptions.clientsPhone.value : PhoneCallOptions.coachPhone.value,
      );
      if (!addClientPhoneOption) {
        setFieldValue('isCoachNumberAdded', true);
      }
      if (values?.isCoachNumberAdded) {
        setPhoneNumberOption(PhoneCallOptions.coachPhone.value);
      }
    }
  };

  useEffect(() => {
    initCallOptions();
  }, [values?.isPhoneNumberAdded, values?.isCoachNumberAdded]);

  useEffect(() => {
    initCallOptions();
  }, [customQuestionsFormRef.current?.values?.enrollmentForm?.enrollmentFormViewModel?.questions]);

  useEffect(() => {
    if (externalCalendarSetToDefault === true) {
      const defaultCal4OneOne = calendars && isArray(calendars) && calendars?.find(k => k.isDefault);
      setSelectedEmailCalendar(defaultCal4OneOne ? defaultCal4OneOne?.emailAddress : null);
      setFieldValue('externalCalendarEmail', defaultCal4OneOne?.emailAddress);
    }
  }, [calendars, externalCalendarSetToDefault]);
  // useLayoutEffect(() => {
  //   // if ([15, 60].includes(sessionDuration)) {
  //   //   setStartTimeIncrementDuration(sessionDuration);
  //   // } else if ([20, 30].includes(sessionDuration)) {
  //   //   setStartTimeIncrementDuration(30);
  //   // } else {
  //   //   setStartTimeIncrementDuration(60);
  //   // }
  //   // const el = durationsOneToOne.find(k => k.value === sessionDuration)?.value;
  //   // if (!isUndefined(el) && isNumber(el)) {
  //   //   setStartTimeIncrementDuration(startIncrementDurationsOneToOne.find(k => k.value === el.value));
  //   // }
  //   if (isMounted && sessionDuration > 0) {
  //     const incrementsLesserOrEqualIncrements = startIncrementDurationsOneToOne.filter(
  //       k => k.value <= (sessionDuration || 60),
  //     );
  //     if (incrementsLesserOrEqualIncrements && incrementsLesserOrEqualIncrements.length) {
  //       // setStartTimeIncrementDuration(incrementsLesserOrEqualIncrements?.at(-1)?.value);
  //       setStartTimeIncrementDuration(incrementsLesserOrEqualIncrements?.at(0)?.value || 0);
  //     }
  //   }
  // }, [sessionDuration]);

  const preparedValues = cloneDeep(values);

  if (oneToOneData.preparedSlots) {
    preparedValues.sessions = values.sessions.map(session => {
      return { ...session, sessionTimes: [...oneToOneData.preparedSlots] };
    });
    // preparedValues.sessions = uniqBy(preparedValues.sessions, o => o.startTime)
  }

  var otherEventsAvailabilityTimesIds = [];
  if (otherEvents && otherEvents.length > 0 && otherEvents[0].sessionTimes) {
    otherEventsAvailabilityTimesIds = otherEvents[0].sessionTimes.filter(e => e.eventId).map(e => e.eventId);
  }
  preparedValues.sessions.forEach(
    st => (st.sessionTimes = st.sessionTimes.filter(e => !otherEventsAvailabilityTimesIds.some(j => j == e.id))),
  );
  preparedValues.sessions = preparedValues.sessions ? [...preparedValues.sessions, ...otherEvents] : otherEvents;
  // preparedValues.sessions = uniqBy(preparedValues.sessions, o => o.startTime)

  // preparedValues.sessions = preparedValues.sessions.filter(k => !isUndefined(k.durations));
  const CalendarsLengthForConflitCheck =
    calendars && isArray(calendars) ? calendars?.filter(c => c.isCheckConflictsEnabled || c.isDefault)?.length : 0;

  const handleTimeChange = (value, sessionTimeIdx, timeType) => {
    if (value) {
      const currentDate = moment(oneToOneStartDate).format('YYYY-MM-DD');
      const currentTime = moment(`${currentDate} ${value.format('HH:mm:ss')}`).format('YYYY-MM-DDTHH:mm:ss[Z]');
      setOneToOneTime(sessionTimeIdx, currentTime, timeType);

      if (timeType === 'startTime') {
        const endTimeValue = value.add(sessionDuration, 'minutes');
        const newEndTime = moment(`${currentDate} ${endTimeValue.format('HH:mm:ss')}`).format('YYYY-MM-DDTHH:mm:ss[Z]');
        setOneToOneTime(sessionTimeIdx, newEndTime, 'endTime');
      }
    }
  };

  const removeTimesHandler = useCallback(i => () => removeBookingTime(i), [removeBookingTime]);

  const addTimesHandler = () => {
    addBookingTime(sessionDuration);
  };

  const givePlatform = connection => {
    let p;
    if (connection === 'live-video') {
      p = Mapper[values?.liveVideoServiceProvider?.providerName];
    } else if (connection === 'phone-call') {
      p = 'Phone Call';
    } else {
      p = 'In Person';
    }
    return p;
  };

  const CustomToolbar = toolbar => {
    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.date.setDate(now.getDate());
      toolbar.onNavigate('current');
    };

    if (mobileView) {
      return (
        <div style={{ padding: '10px' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <div style={{ width: '63%' }}>
              <DateNavigationComponent toolbar={toolbar} calendarActiveView={calendarActiveView} />
            </div>
            <div style={{ width: '40%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div
                style={{
                  padding: '7px',
                  alignItems: 'center',
                  background: '#e7e7e7',
                  fontFamily: 'Avenir',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  borderRadius: '4px',
                }}
                role="button"
                // tabIndex={0}
                onClick={goToCurrent}
                onKeyPress={goToCurrent}
              >
                <p style={{ margin: 'unset' }}>Today</p>
              </div>
              <MUISelect
                variant="outlined"
                // displayEmpty
                value={currentCalendarView}
                MenuProps={{
                  PaperProps: {
                    style: { fontFamily: 'Avenir' },
                  },
                }}
                style={{ width: '65px', height: '35px' }}
                onChange={() => {}}
              >
                {viewTabButtonsData.map(value => (
                  <MenuItem
                    value={value}
                    onClick={() => {
                      setCurrentCalendarView(value);
                      dispatch(calendarAction.setCurrentCalendarView.request(value));
                    }}
                  >
                    {value}
                  </MenuItem>
                ))}
              </MUISelect>
            </div>
          </div>
          <div className="calendar-custom-header">
            <Button
              type="button"
              className="calendar-add-availability-button"
              onClick={() => {
                setshowAvailabilityPopUp(true);
              }}
            >
              <AddIcon />
              <p>Add One-Time Availability</p>
            </Button>
          </div>
        </div>
      );
    }

    return (
      <span>
        <div className="calendar-custom-header" style={{ marginBottom: '30px' }}>
          <DateNavigationComponent toolbar={toolbar} calendarActiveView={calendarActiveView} />
          <div style={{ display: 'flex', flexDirection: 'column', float: 'right' }}>
            {mobileView && (
              <div
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                }}
              >
                <div
                  style={{
                    padding: '8px 20px 10px 20px',
                    alignItems: 'center',
                    background: '#e7e7e7',
                    fontFamily: 'Avenir',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    borderRadius: '4px',
                    margin: 'auto 0 auto 5px',
                    marginTop: '5px',
                    marginBottom: '10px',
                  }}
                  role="button"
                  // tabIndex={0}
                  onClick={goToCurrent}
                  onKeyPress={goToCurrent}
                >
                  <p style={{ margin: 'unset' }}>Today</p>
                </div>
                <MUISelect
                  variant="outlined"
                  // displayEmpty
                  value={currentCalendarView}
                  MenuProps={{
                    PaperProps: {
                      style: { fontFamily: 'Avenir' },
                    },
                  }}
                  style={{ width: '80px', height: '35px' }}
                  onChange={() => {}}
                >
                  {viewTabButtonsData.map(value => (
                    <MenuItem
                      value={value}
                      onClick={() => {
                        setCurrentCalendarView(value);
                        dispatch(calendarAction.setCurrentCalendarView.request(value));
                      }}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </MUISelect>
              </div>
            )}
            <Button
              type="button"
              className="calendar-create-session-button"
              onClick={() => {
                setshowAvailabilityPopUp(true);
              }}
            >
              <AddIcon />
              <p>Add One-Time Availability</p>
            </Button>
          </div>
        </div>
      </span>
    );
  };

  useEffect(() => {
    if (phoneInputRef.current && !values?.coachPhoneNumber) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.addEventListener('focus', () => {
          setCoachPhoneNumberFocused(true);
        });
      }
      return () => {
        if (phoneInput) {
          phoneInput.removeEventListener('focus', () => {
            setCoachPhoneNumberFocused(false);
            setCoachPhoneNumber('');
          });
        }
      };
    }
    if (values?.coachPhoneNumber) {
      setCoachPhoneNumberFocused(true);
      setCoachPhoneNumber(values?.coachPhoneNumber);
    }
  }, [phoneInputRef.current]);

  useEffect(() => {
    if (coachPhoneNumberFocused && phoneInputRef.current) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.focus();
      }
    }
  }, [coachPhoneNumberFocused]);

  const setDefaultCountryCode = async () => {
    const countryCode = await fetchUserCountryCodeByIp(contribution?.timeZoneId, timeZones, countries);
    setDefaultCountry(countryCode);
  };

  useEffect(() => {
    if (timeZones?.length > 0 && countries?.length > 0) setDefaultCountryCode();
  }, [timeZones, countries]);

  const resetCoachPhone = () => {
    setCoachPhoneNumberFocused(false);
    setCoachPhoneNumber('');
    setFieldValue(`coachPhoneNumber`, '');
  };

  return (
    <Form id="create-contribution-form">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card mobileView={mobileView}>
            <StyledCardHeader mobileView={mobileViewXs} ForEditSessionsHeader>
              <StyledFullWidthFlexContainer mobileView={mobileView}>
                <PageTitleSecond mobileView={mobileView}>3. Session Booking Availability</PageTitleSecond>
                <NavLink to="/account/profile">
                  <StyledTimeZoneLabel mobileView={mobileView}>{TIMEZONES[user.timeZoneId]}</StyledTimeZoneLabel>
                </NavLink>
              </StyledFullWidthFlexContainer>

              <StyledCalendarTabContainer mobileView={mobileView} ForEditSessionsHeader wrapCalendars={wrapCalendars}>
                <StyledCalendarsContainer
                  onlyChild={CalendarsLengthForConflitCheck === 1}
                  className={'no-scroll-container'}
                  mobileView={mobileView}
                  ForEditSessionsHeader
                  // style={{ padding: accountsForConflictsCheck?.length > 0 ? '' : '0px' }}
                  style={{ maxWidth: mobileView ? 290 : 550 }}
                >
                  {calendars &&
                    isArray(calendars) &&
                    calendars
                      ?.filter(k => k.isCheckConflictsEnabled && k.emailAddress != values?.externalCalendarEmail)
                      ?.map((calendar, i) => {
                        const { provider, emailAddress, isCheckConflictsEnabled, isEventRemindersEnabled } = calendar;
                        const key = `${i}${emailAddress}`;
                        // const Icon = getDomainIcon(null, provider);
                        const Icon = chooseProviderIcon(provider);
                        return (
                          <StyledCalendar key={key}>
                            {Icon && <Icon className="provider-logo" />}
                            <Tooltip
                              title={emailAddress}
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <StyledEmail className="ml-1">{emailAddress}</StyledEmail>
                            </Tooltip>
                            <Tooltip
                              title="Disable checking double booking for this account"
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <StyledCloseIcon onClick={() => onRemoveCalendar(emailAddress)} />
                            </Tooltip>
                            {/* <SettingsIcon
                              className="settings-icon"
                              onClick={() => {
                                setCalendarForSettingsPopup(calendar);
                                setShowExternalCalendarSettingsModal(true);
                              }}
                            /> */}
                          </StyledCalendar>
                        );
                      })}
                </StyledCalendarsContainer>
                <StyledFlexContainer>
                  {values?.externalCalendarEmail ? (
                    <>
                      {/* <StyledCalendar style={{ height: 50 }} key={Math.random(10000)}>
                        <StyledEmail className="ml-2">{selectedEmailCalendar}</StyledEmail>
                        <StyledCloseIcon onClick={() => onRemoveSelectedEmailCalendar()} />
                        <SettingsIcon
                          onClick={() => {
                            // setCalendarForSettingsPopup(calendar);
                            // setShowExternalCalendarSettingsModal(true);
                          }}
                        />
                      </StyledCalendar> */}
                      {/* <StyledCalendar style={{ height: 50 }} >
                        <Icon />
                        <Tooltip
                          title={selectedEmailCalendar}
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <StyledEmail className="ml-1">{selectedEmailCalendar}</StyledEmail>
                        </Tooltip> */}
                      {/* <StyledCloseIcon onClick={() => onRemoveCalendar(emailAddress)} /> */}
                      {/* <SettingsIcon
                              className="settings-icon"
                              onClick={() => {
                                setCalendarForSettingsPopup(calendar);
                                setShowExternalCalendarSettingsModal(true);
                              }}
                            /> */}
                      {/* </StyledCalendar> */}
                      {calendars &&
                        isArray(calendars) &&
                        calendars
                          // ?.filter(k => k.isDefault)
                          ?.filter(k => k.emailAddress === values?.externalCalendarEmail)
                          ?.map((calendar, i) => {
                            const {
                              provider,
                              emailAddress,
                              isCheckConflictsEnabled,
                              isEventRemindersEnabled,
                            } = calendar;
                            const key = `${i}${emailAddress}`;
                            // const Icon = getDomainIcon(null, provider);
                            const Icon = chooseProviderIcon(provider);
                            return (
                              <StyledCalendar key={key}>
                                {Icon && <Icon className="provider-logo" />}
                                <Tooltip
                                  title={emailAddress}
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <StyledEmail className="ml-1">{emailAddress}</StyledEmail>
                                </Tooltip>
                                <Tooltip
                                  title="Remove sending invites from selected account"
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <StyledCloseIcon onClick={onRemoveSelectedEmailCalendar} />
                                </Tooltip>
                                {/* <Tooltip
                                  title="Change account to send invites from"
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <SettingsIcon
                                    className="settings-icon"
                                    onClick={handleOpenSendInvitesFromModalOpen}
                                  />
                                </Tooltip> */}
                              </StyledCalendar>
                            );
                          })}
                    </>
                  ) : (
                    <>
                      {/* <StyledFlexContainer>
                        <StyledButton mobileView={mobileView} autoWidth onClick={handleOpenSendInvitesFromModalOpen}>
                          Send invite from
                        </StyledButton>
                        <StyledTooltip
                          title="Select the calendar you wish to send an invite from to your clients."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor={colors.lightBrown} />
                        </StyledTooltip>
                      </StyledFlexContainer> */}
                    </>
                  )}
                  {mobileView && (
                    <StyledTooltip
                      title="Adding your calendar(s) prevents double booking. Cohere will automatically show clients ONLY the times you are available by cross-referencing your personal calendars."
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <InfoIcon htmlColor={colors.lightBrown} />
                    </StyledTooltip>
                  )}
                  <StyledButton mobileView={mobileView} autoWidth onClick={handleOpenAddCalendarModal}>
                    {isArray(calendars) && calendars?.length > 0 ? 'Manage Calendars' : 'Add My Calendar'}
                  </StyledButton>
                  {!mobileView && (
                    <StyledTooltip
                      title="Adding your calendar(s) prevents double booking. Cohere will automatically show clients ONLY the times you are available by cross-referencing your personal calendars."
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <InfoIcon htmlColor={colors.lightBrown} />
                    </StyledTooltip>
                  )}
                </StyledFlexContainer>
              </StyledCalendarTabContainer>
            </StyledCardHeader>
            <CardBody mobileView={mobileView}>
              <CustomExpansionPanel
                summaryTitle="What Are Your Session Details?"
                summarySubTitle={`${sessionDuration === 60 ? '1 hour' : sessionDuration} ${
                  sessionDuration === 60 ? '' : 'min'
                } Session, ${givePlatform(connectionOption)}`}
                mobileView={mobileView}
                expand={true}
              >
                <Grid id="event-details" container spacing={2}>
                  <Grid item md={6} xs={12} direction="column">
                    <div
                      style={{
                        fontFamily: 'Avenir',
                        fontSize: mobileView ? '15px' : '16px',
                        fontWeight: '800',
                        lineHeight: '21px',
                        letterSpacing: '0.1px',
                        width: '100%',
                        marginBottom: '15px',
                      }}
                    >
                      How do you want to connect with your clients?
                    </div>
                    <RadioGroup
                      // row
                      name="connection_option"
                      onChange={({ target }) => {
                        if (target.value === 'phone-call') {
                          setFieldValue('isPhoneNumberAdded', true);
                          setPhoneNumberOption(
                            values?.isCoachNumberAdded
                              ? PhoneCallOptions.coachPhone.value
                              : PhoneCallOptions.clientsPhone.value,
                          );
                        } else {
                          setFieldValue('isPhoneNumberAdded', false);
                          resetCoachPhone();
                        }

                        if (target.value === 'phone-call') {
                          setFieldValue('customInvitationBody', customInvitaionBodyText.phoneCall);
                        } else if (target.value === 'live-video') {
                          setFieldValue('customInvitationBody', customInvitaionBodyText.cohereVideo);
                          setFieldValue('liveVideoServiceProvider.providerName', 'Cohere');
                          setFieldValue('liveVideoServiceProvider.customLink', '');
                        } else {
                          setFieldValue('customInvitationBody', customInvitaionBodyText.inPerson);
                          setFieldValue('liveVideoServiceProvider.providerName', 'custom');
                          setFieldValue('liveVideoServiceProvider.customLink', '');
                        }
                        setConnectionOption(target.value);
                      }}
                      value={connectionOption}
                    >
                      <div style={{ gap: '20px' }}>
                        <div className="d-flex align-items-center">
                          <FormControlLabel
                            style={{ marginBottom: 0, marginRight: '10px' }}
                            value="live-video"
                            control={
                              <CustomRadio
                                disableRipple={true}
                                classes={{
                                  colorSecondary: classes.color_secondary,
                                }}
                              />
                            }
                            label="Live Video"
                          />
                          <Tooltip
                            title="If you want to meet with your clients online using Cohere video or Zoom, select this option"
                            arrow
                            enterTouchDelay={TOOLTIP.ENTER_DELAY}
                            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                          >
                            <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
                          </Tooltip>
                        </div>
                        <div className="d-flex align-items-center">
                          <FormControlLabel
                            style={{
                              marginBottom: 0,
                              marginRight: '10px',
                              '&. MuiFormControlLabel-root': {
                                fontSize: '4px !important',
                                '&. MuiTypography-body1': {
                                  fontSize: '4px !important',
                                },
                              },
                            }}
                            value="phone-call"
                            control={
                              <CustomRadio
                                disableRipple={true}
                                classes={{
                                  colorSecondary: classes.color_secondary,
                                }}
                              />
                            }
                            label="Phone Call"
                          />
                          <Tooltip
                            title="If you prefer to meet with your clients over the phone, select this option"
                            arrow
                            enterTouchDelay={TOOLTIP.ENTER_DELAY}
                            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                          >
                            <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
                          </Tooltip>
                        </div>
                        <div className="d-flex align-items-center">
                          <FormControlLabel
                            style={{ marginBottom: 0, marginRight: '10px' }}
                            value="in-person"
                            control={
                              <CustomRadio
                                disableRipple={true}
                                classes={{
                                  colorSecondary: classes.color_secondary,
                                }}
                              />
                            }
                            label="In Person Session"
                          />
                          <Tooltip
                            title="If you want to meet with your clients online using Cohere video or Zoom, select this option"
                            arrow
                            enterTouchDelay={TOOLTIP.ENTER_DELAY}
                            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                          >
                            <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
                          </Tooltip>
                        </div>
                      </div>
                    </RadioGroup>
                    {!mobileView && (
                      <>
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontSize: '16px',
                            fontWeight: '800',
                            lineHeight: '21px',
                            letterSpacing: '0.1',
                            width: '100%',
                            marginBottom: '15px',
                            marginTop: '10px',
                          }}
                        >
                          How long are your sessions?
                        </div>
                        <SelectStyled
                          style={{
                            fontFamily: 'Avenir',
                            border: '1px solid #DFE3E4',
                            borderRadius: '4px',
                            fontSize: '16px',
                            fontWeight: '350',
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                          }}
                          onChange={onChangeSessionDuration}
                          value={sessionDuration}
                          fullWidth
                          items={durationsOneToOne.map(({ title, value }) => ({
                            title,
                            value,
                          }))}
                          disabled={alreadyBooked}
                          error={sessionDurationError}
                          errorHeight={sessionDurationError ? 50 : 0}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12} direction="column">
                    {connectionOption === 'live-video' && (
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontSize: mobileView ? '15px' : '16px',
                              fontWeight: '800',
                              lineHeight: '21px',
                              letterSpacing: '0.1px',
                              marginBottom: '15px',
                            }}
                          >
                            Select video provider
                          </div>
                          {/* {user.isZoomEnabled && isZoomSelected && (
                            <div>
                              <div className={classes.container}>
                                <div
                                  style={{
                                    fontFamily: 'Avenir',
                                    fontSize: mobileView ? '15px' : '16px',
                                    fontWeight: '800',
                                    lineHeight: '21px',
                                    letterSpacing: '0.1px',
                                  }}
                                >
                                  Turn on waiting room
                                </div>
                                <div className={classes.toggle_btn}>
                                  <SlimSwitch className="switch">
                                    <StyledInput
                                      type="checkbox"
                                      onClick={e => {
                                        setFieldValue('zoomWaitingRoomDisabled', !e.target.checked);
                                      }}
                                      checked={!values?.zoomWaitingRoomDisabled}
                                    />
                                    <SlimStyledSlider className="slider round" />
                                  </SlimSwitch>
                                </div>
                              </div>
                            </div>
                          )} */}
                        </div>

                        <Select
                          style={{
                            fontFamily: 'Avenir',
                            border: '1px solid #DFE3E4',
                            borderRadius: '4px',
                            padding: '3px 10px',
                            fontSize: '16px',
                            fontWeight: '350',
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                          }}
                          name="liveVideoServiceProvider.providerName"
                          onChange={({ target }) => {
                            setFieldValue(target.name, target.value);
                            if (target.value === 'Cohere') {
                              setFieldValue('customInvitationBody', customInvitaionBodyText.cohereVideo);
                            } else if (target.value === 'Zoom') {
                              setFieldValue('customInvitationBody', customInvitaionBodyText.zoomVideo);
                              setShowTerms(true);
                            } else if (target.value === 'Custom') {
                              setFieldValue('customInvitationBody', customInvitaionBodyText.privateLink);
                            } else {
                              setFieldValue('customInvitationBody', customInvitaionBodyText.inPerson);
                            }
                          }}
                          fullWidth
                          items={liveVideoProviders}
                        />
                        {/* {user.isZoomEnabled && isZoomSelected && (
                          <div className={classes.container}>
                            <div className={classes.heading}>Turn on waiting room</div>
                            <div className={classes.toggle_btn}>
                              <SlimSwitch className="switch">
                                <StyledInput
                                  type="checkbox"
                                  onClick={e => {
                                    setFieldValue('zoomWaitingRoomDisabled', !e.target.checked);
                                  }}
                                  checked={!values?.zoomWaitingRoomDisabled}
                                />
                                <SlimStyledSlider className="slider round" />
                              </SlimSwitch>
                            </div>
                          </div>
                        )} */}
                      </div>
                    )}

                    {connectionOption === 'phone-call' && (
                      <div style={{ marginTop: mobileView ? '' : '35px' }}>
                        <Select
                          style={{
                            fontFamily: 'Avenir',
                            border: '1px solid #DFE3E4',
                            borderRadius: '4px',
                            padding: '3px 10px',
                            fontSize: '16px',
                            fontWeight: '350',
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                          }}
                          name="isPhoneNumberAdded"
                          value={phoneNumberOption}
                          onChange={({ target }) => {
                            if (PhoneCallOptions.clientsPhone.value) {
                              resetCoachPhone();
                            }
                            setPhoneNumberOption(target.value);
                            setFieldValue('isCoachNumberAdded', target.value === PhoneCallOptions.coachPhone.value);
                            setFieldValue('customInvitationBody', customInvitaionBodyText.phoneCall);
                          }}
                          fullWidth
                          items={phoneCallOptions}
                        />

                        {phoneNumberOption === PhoneCallOptions.coachPhone.value && (
                          <div ref={phoneInputRef}>
                            <StyledPhoneInput
                              key={coachPhoneNumberFocused}
                              value={coachPhoneNumber}
                              className={`my-2 coach-phone ${
                                getIn(errors, 'coachPhoneNumber') && getIn(touched, 'coachPhoneNumber')
                                  ? 'phone-error'
                                  : ''
                              }`}
                              {...(!coachPhoneNumber && { disableCountryGuess: true })}
                              {...(!coachPhoneNumber && { defaultCountry })}
                              {...(!coachPhoneNumberFocused && { disableDialCodePrefill: true })}
                              {...(coachPhoneNumberFocused && { forceDialCode: true })}
                              disableCountryGuess
                              onChange={phone => {
                                setFieldValue(`coachPhoneNumber`, phone);
                                setCoachPhoneNumber(phone);
                              }}
                              mobileView={mobileView}
                              placeholder="Phone number"
                            />
                            {getIn(errors, 'coachPhoneNumber') && getIn(touched, 'coachPhoneNumber') && (
                              <span className="text-danger">Enter a valid phone number</span>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {(connectionOption === 'in-person' ||
                      (values.liveVideoServiceProvider &&
                        values.liveVideoServiceProvider.providerName === LiveVideoProvider.Custom.value)) && (
                      <div style={{ marginTop: mobileView ? '' : connectionOption === 'in-person' ? '35px' : '5px' }}>
                        <TextArea
                          style={{
                            fontFamily: 'Avenir',
                            border: '1px solid #DFE3E4',
                            borderRadius: '4px',
                            padding: '10px',
                            fontSize: '16px',
                            fontWeight: '350',
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                          }}
                          labelName="meetingLocation"
                          placeholder={
                            values?.liveVideoServiceProvider?.providerName === 'Custom'
                              ? 'Enter a private link'
                              : 'Enter Location'
                          }
                          name="liveVideoServiceProvider.customLink"
                          rows="1"
                          fullWidth
                          helperTextPosition="right"
                          id="contribution-details-bio-textarea"
                          noLabel={true}
                        />
                      </div>
                    )}

                    <div
                      style={{
                        marginTop: '4px',
                        width: '100%',
                        height: '100%',
                        fontFamily: 'Brandon Text',
                      }}
                    >
                      <div style={{ marginBottom: '5px', marginTop: '7px' }}>
                        Calendar invite body text
                        {/* <Tooltip
                          style={{ fontFamily: 'Brandon Text' }}
                          title="This is the meeting location of your sessions. Feel free to link an address, video conferencing link, etc."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <StyledInfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                        </Tooltip> */}
                      </div>
                      <TextArea
                        style={{
                          fontFamily: 'Avenir',
                          fontSize: '16px',
                          fontWeight: '350',
                          lineHeight: '20px',
                          // letterSpacing: '0px',
                          // textAlign: 'left',
                        }}
                        labelName="CustomInvitationBody"
                        placeholder="This section is used to share a short bio. This is an opportunity to include your professional title, background, experience and your mission."
                        name="customInvitationBody"
                        rows={5}
                        fullWidth
                        helperTextPosition="right"
                        id="contribution-details-bio-textarea"
                        noLabel={true}
                      />
                    </div>
                  </Grid>
                  {mobileView && (
                    <>
                      <div
                        style={{
                          fontFamily: 'Avenir',
                          fontSize: '16px',
                          fontWeight: '800',
                          lineHeight: '21px',
                          letterSpacing: '0.1',
                          width: '100%',
                          marginBottom: '15px',
                          marginTop: '10px',
                          padding: '0px 8px',
                        }}
                      >
                        How long are your sessions?
                      </div>
                      <div style={{ padding: '0px 8px', width: '100%' }}>
                        <Select
                          style={{
                            fontFamily: 'Avenir',
                            border: '1px solid #DFE3E4',
                            borderRadius: '4px',
                            padding: '3px 10px',
                            fontSize: '16px',
                            fontWeight: '350',
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                          }}
                          onChange={onChangeSessionDuration}
                          value={sessionDuration}
                          fullWidth
                          items={durationsOneToOne.map(({ title, value }) => ({
                            title,
                            value,
                          }))}
                          disabled={alreadyBooked}
                          error={sessionDurationError}
                          errorHeight={sessionDurationError ? 50 : 0}
                        />
                      </div>
                    </>
                  )}
                </Grid>
              </CustomExpansionPanel>
              <CustomExpansionPanel
                summaryTitle={'What is Your Availability?'}
                summarySubTitle={`${`${daysDiff} days into the future`}, ${
                  ifAllAreConsecutiveDays(firstSelectedWeekDaysArraySorted)
                    ? `${firstSelectedWeekDaysArraySorted[0]} to ${
                        firstSelectedWeekDaysArraySorted[firstSelectedWeekDaysArraySorted.length - 1]
                      }`
                    : joinedSelectedWeekDays
                } ${firstSelectedWeekStartTime}-${firstSelectedWeekEndTime}...`}
                mobileView={mobileView}
                setMainExpand={setIsSchedulingDetailExpanded}
                expand={isSchedulingDetailExpanded}
                noTopPadding={true}
              >
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <CustomExpansionPanel
                      summaryTitle={
                        <div className="d-flex align-items-center">
                          <p
                            style={{
                              fontFamily: 'Avenir',
                              fontSize: '16px',
                              fontWeight: '800',
                              lineHeight: '21px',
                              letterSpacing: '0.1',
                              marginBottom: '0px',
                            }}
                          >
                            Date range
                          </p>
                          <Tooltip
                            title="This allows your calendar availability to always have the next indicated number of days available for client booking. You can also select custom dates."
                            arrow
                            enterTouchDelay={TOOLTIP.ENTER_DELAY}
                            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                          >
                            <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                          </Tooltip>
                        </div>
                      }
                      expand={true}
                      mobileView={mobileView}
                      noBorder
                    >
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <StyledClientBook>Clients can book...</StyledClientBook>
                        <div style={{ marginTop: '15px' }}>
                          <RadioGroup
                            // row
                            name="booking_range"
                            onChange={({ target }) => {
                              setBookingRange(target.value);
                              if (target.value === 'within-the-range') {
                                setOneToDateRange('custom');
                              }
                            }}
                            value={bookingRange}
                          >
                            <div style={{ gap: '20px' }}>
                              <div className="d-flex align-items-center">
                                <FormControlLabel
                                  style={{ marginBottom: 0, marginRight: '10px' }}
                                  value="into-the-future"
                                  control={<CustomRadio />}
                                  label={
                                    <div
                                      style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <Select
                                        style={{
                                          fontFamily: 'Avenir',
                                          border: '1px solid #DFE3E4',
                                          borderRadius: '4px',
                                          padding: '3px 10px',
                                          fontSize: '16px',
                                          fontWeight: '350',
                                          lineHeight: '24px',
                                          letterSpacing: '0.15px',
                                          marginRight: '10px',
                                        }}
                                        labelTop={mobileView ? '32px' : '24px'}
                                        name="daterange"
                                        variant="Outlined"
                                        value={oneToDateRange}
                                        onChange={e => {
                                          if (e.target.value === 'custom') {
                                            setBookingRange('within-the-range');
                                          }
                                          setOneToDateRange(e.target.value);
                                        }}
                                        items={dateRangeOneToOne.map(({ title, value }) => ({
                                          title,
                                          value,
                                        }))}
                                        disabled={bookingRange !== 'into-the-future'}
                                      />
                                      into the future
                                    </div>
                                  }
                                />
                              </div>
                              <div className="d-flex align-items-center">
                                <FormControlLabel
                                  style={{ marginBottom: 0 }}
                                  value="within-the-range"
                                  control={<CustomRadio />}
                                  label="Within the date range"
                                />
                                <Tooltip
                                  title="Your clients can only book appointments within this date range. Once it ends, you'll need to log back in to add more availability."
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <InfoIcon
                                    htmlColor={colors.lightBrown}
                                    style={{ cursor: 'pointer', marginLeft: '-8px' }}
                                  />
                                </Tooltip>
                              </div>
                              {bookingRange === 'within-the-range' && (
                                <Grid
                                  item
                                  container
                                  sm={12}
                                  xs={12}
                                  direction="row"
                                  justifyContent="space-between"
                                  style={{ marginTop: '10px', marginLeft: '0px' }}
                                >
                                  <Grid sm={5} xs={5}>
                                    <DateTimePicker
                                      disabled={oneToDateRange != 'custom'}
                                      // label={<div style={{ fontFamily: 'Brandon Text' }}>Start Day</div>}
                                      style={{
                                        fontFamily: 'Avenir',
                                        border: '1px solid #DFE3E4',
                                        borderRadius: '4px',
                                        padding: '3px 10px',
                                        fontSize: '16px',
                                        fontWeight: '350',
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                        marginBottom: mobileView ? '10px' : '',
                                      }}
                                      type="date"
                                      fullWidth
                                      name="startDate"
                                      value={oneToOneStartDate}
                                      onChange={momentDate => {
                                        setStartDate(momentDate);
                                      }}
                                      format={mobileView ? 'MM/DD/YYYY' : 'MMMM Do YYYY'}
                                      underline={false}
                                    />
                                  </Grid>
                                  <Grid sm={5} xs={5}>
                                    <DateTimePicker
                                      disabled={oneToDateRange != 'custom'}
                                      // label={<div style={{ fontFamily: 'Brandon Text' }}>End Day</div>}
                                      style={{
                                        fontFamily: 'Avenir',
                                        border: '1px solid #DFE3E4',
                                        borderRadius: '4px',
                                        padding: '3px 10px',
                                        fontSize: '16px',
                                        fontWeight: '350',
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                      }}
                                      type="date"
                                      fullWidth
                                      name="endDate"
                                      value={oneToOneEndDate}
                                      onChange={momentDate => {
                                        setEndDate(momentDate);
                                      }}
                                      format={mobileView ? 'MM/DD/YYYY' : 'MMMM Do YYYY'}
                                      underline={false}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            </div>
                          </RadioGroup>
                        </div>
                      </div>
                    </CustomExpansionPanel>
                    <div>
                      <CustomExpansionPanel
                        summaryTitle={
                          <div className="d-flex align-items-center">
                            <p
                              style={{
                                fontFamily: 'Avenir',
                                fontSize: mobileView ? '14px' : '16px',
                                fontWeight: '800',
                                lineHeight: '21px',
                                letterSpacing: '0.1',
                                marginBottom: '0px',
                              }}
                            >
                              Add Weekly Schedules: What days and times can be booked?
                            </p>
                            {/* <Tooltip
                              title="If you prefer to meet with your clients over the phone, select this option"
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
                            </Tooltip> */}
                          </div>
                        }
                        expand={true}
                        disableCollapse={isExpansionDisabled}
                        mobileView={mobileView}
                        noBorder
                      >
                        <FieldArray name={`sessions[0].sessionTimes`} validateOnChange={false}>
                          {({ push, remove, form }) => {
                            return (
                              // <StyledSessionTimesContainer mobileView={mobileView}>
                              // <SessionTimeOneToOne
                              //   form={form}
                              //   push={push}
                              //   remove={remove}
                              //   isOneToOne={true}
                              //   sessionIdx={0}
                              //   timesCount={timesCount}
                              //   bufferTimeBefore={values?.bufferTimeBefore}
                              //   bufferTimeAfter={values?.bufferTimeAfter}
                              //   oneToOneStartDate={oneToOneStartDate}
                              //   oneToOneEndDate={oneToOneEndDate}
                              //   oneToDateRange={oneToDateRange}
                              //   otherEvents={otherEvents}
                              // />
                              // </StyledSessionTimesContainer>
                              <div style={{ width: '100%' }}>
                                {selectedWeeks.length && (
                                  <Grid container>
                                    {selectedWeeks.map((week, i) => {
                                      const momentStartTime = moment.utc(week.startTime);
                                      const momentEndTime = moment.utc(week.endTime);
                                      const key = i;
                                      return (
                                        <Fragment key={key}>
                                          <Grid container>
                                            <StyledDivContainer smView={smView}>
                                              <Grid sm={smView ? 12 : 7} xs={smView ? 12 : 7} style={{ width: '100%' }}>
                                                <div className="d-flex" style={{ width: '100%' }}>
                                                  <StyledFormControl>
                                                    {/* <InputLabel
                                                      style={{ color: 'black', fontFamily: 'Brandon Text' }}
                                                      shrink
                                                      htmlFor={`available-time-${i}`}
                                                    >
                                                      Add available times: What days and times can be booked?
                                                    </InputLabel> */}
                                                    <WeekdayPicker itemIndex={i} />
                                                  </StyledFormControl>
                                                </div>
                                              </Grid>
                                              <Grid
                                                sm={smView ? 12 : 5}
                                                xs={smView ? 12 : 5}
                                                style={{ marginLeft: smView ? 0 : 8 }}
                                              >
                                                <Grid
                                                  container
                                                  direction="row"
                                                  justify="space-around"
                                                  alignItems="baseline"
                                                >
                                                  <Grid item sm={4} xs={4}>
                                                    <div className="d-flex">
                                                      <StyledFormControl>
                                                        {/* <InputLabel
                                                          htmlFor={`start-time-${i}`}
                                                          style={{ top: '-20px', fontFamily: 'Brandon Text' }}
                                                        >
                                                          From
                                                        </InputLabel> */}
                                                        <TimePickerField
                                                          onChange={value => handleTimeChange(value, i, 'startTime')}
                                                          onOpen={e => {
                                                            setIsExpansionDisabled(true);
                                                          }}
                                                          onClose={e => {
                                                            setIsExpansionDisabled(false);
                                                          }}
                                                          style={{
                                                            fontFamily: 'Avenir',
                                                            border: '1px solid #e7e7e7',
                                                            borderRadius: '4px',
                                                            alignItems: 'center',
                                                          }}
                                                          className="custom-time-picker-nounderline"
                                                          name={`start-time-${i}`}
                                                          id={`tstart-time-${i}`}
                                                          classes={{
                                                            root: 'mt-4 mr-3',
                                                          }}
                                                          showSecond={false}
                                                          value={momentStartTime}
                                                          format="h:mm a"
                                                          use12Hours
                                                          minuteStep={15}
                                                        />
                                                      </StyledFormControl>
                                                    </div>
                                                  </Grid>
                                                  <Grid item sm={1} xs={1}>
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                      }}
                                                    >
                                                      <StyledBsDash />
                                                    </div>
                                                  </Grid>
                                                  <Grid item sm={4} xs={4}>
                                                    <div className="d-flex">
                                                      <StyledFormControl>
                                                        {/* <InputLabel
                                                          htmlFor={`end-time-${i}`}
                                                          style={{ top: '-20px', fontFamily: 'Brandon Text' }}
                                                        >
                                                          To
                                                        </InputLabel> */}
                                                        <TimePickerField
                                                          style={{
                                                            fontFamily: 'Avenir',
                                                            border: '1px solid #e7e7e7',
                                                            borderRadius: '4px',
                                                            alignItems: 'center',
                                                          }}
                                                          className="custom-time-picker-nounderline"
                                                          onChange={value => handleTimeChange(value, i, 'endTime')}
                                                          onOpen={e => {
                                                            setIsExpansionDisabled(true);
                                                          }}
                                                          onClose={e => {
                                                            setIsExpansionDisabled(false);
                                                          }}
                                                          name={`end-time-${i}`}
                                                          id={`tend-time-${i}`}
                                                          classes={{
                                                            root: 'mt-4 mr-3',
                                                          }}
                                                          showSecond={false}
                                                          value={momentEndTime}
                                                          format="h:mm a"
                                                          use12Hours
                                                          minuteStep={15}
                                                        />
                                                      </StyledFormControl>
                                                    </div>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    sm={1}
                                                    xs={1}
                                                    style={{
                                                      textAlign: 'right',
                                                      justifyContent: 'flex-end',
                                                    }}
                                                    // classes={{
                                                    //   root: 'd-flex align-items-center',
                                                    // }}
                                                  >
                                                    &nbsp;
                                                    {selectedWeeks.length > 1 && (
                                                      <StyledIcon
                                                        onClick={removeTimesHandler(i)}
                                                        path={mdiMinusCircleOutline}
                                                        size={1}
                                                        style={{ color: colors.darkOceanBlue }}
                                                      />
                                                    )}
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </StyledDivContainer>
                                          </Grid>
                                        </Fragment>
                                      );
                                    })}
                                  </Grid>
                                )}
                                <div style={{ marginTop: '15px' }}>
                                  <StyledLink onClick={addTimesHandler}>
                                    <Icon
                                      path={mdiPlus}
                                      style={{ width: '14px', height: '14px' }}
                                      color={colors.darkOceanBlue}
                                    />
                                    Add Additional Booking Times
                                  </StyledLink>
                                </div>
                              </div>
                            );
                          }}
                        </FieldArray>
                      </CustomExpansionPanel>

                      <CustomExpansionPanel
                        summaryTitle={
                          <div className="d-flex align-items-center">
                            <p
                              style={{
                                fontFamily: 'Avenir',
                                fontSize: mobileView ? '14px' : '16px',
                                fontWeight: '800',
                                lineHeight: '21px',
                                letterSpacing: '0.1',
                                marginBottom: '0px',
                                marginRight: '10px',
                              }}
                            >
                              Add Buffer Time: Before or after sessions?
                            </p>
                            <Tooltip
                              title="If you add a buffer before or after your sessions, you will have a time break that clients can not reserve."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </div>
                        }
                        summarySubTitle={giveTitle(bufferTimeBefore, bufferTimeAfter)}
                        expand={false}
                        mobileView={mobileView}
                        subTitleBelowTheTitle={true}
                        noBorder
                      >
                        <Grid container xs={12} spacing={1}>
                          <Grid item direction="column" md={2} sm={3} xs={6}>
                            <FormControlLabel
                              style={{ fontFamily: 'Brandon Text' }}
                              checked={isBeforeBufferActive}
                              onChange={() => {
                                setIsBeforeBufferActive(!isBeforeBufferActive);
                                if (isBeforeBufferActive === true) {
                                  setFieldValue('bufferTimeBefore', 0);
                                }
                              }}
                              control={<Checkbox color="primary" />}
                              label={<StyledLabel>Before event</StyledLabel>}
                              name="isBeforeBufferActive"
                              disabled={false}
                            />
                            <Select
                              labelTop="0px"
                              // name='name'
                              style={{
                                fontFamily: 'Avenir',
                                border: '1px solid #DFE3E4',
                                borderRadius: '4px',
                                padding: '3px 10px',
                                fontSize: '16px',
                                fontWeight: '350',
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                              }}
                              value={bufferTimeBefore}
                              onChange={e => {
                                setFieldValue('bufferTimeBefore', e.target.value);
                              }}
                              fullWidth
                              items={bufferTimeValues?.map(({ title, value }) => ({
                                title,
                                value,
                              }))}
                              disabled={!isBeforeBufferActive}
                              error=""
                            />
                          </Grid>
                          <Grid item direction="column" md={2} sm={3} xs={6}>
                            <FormControlLabel
                              style={{ fontFamily: 'Brandon Text' }}
                              checked={isAfterBufferActive}
                              onChange={() => {
                                setIsAfterBufferActive(!isAfterBufferActive);
                                if (isAfterBufferActive === true) {
                                  setFieldValue('bufferTimeAfter', 0);
                                }
                              }}
                              control={<Checkbox color="primary" />}
                              label={<StyledLabel>After event</StyledLabel>}
                              name="isAfterBufferActive"
                              disabled={false}
                            />
                            <Select
                              labelTop="0px"
                              // name={`sessions[${index}].daterange`}
                              style={{
                                fontFamily: 'Avenir',
                                border: '1px solid #DFE3E4',
                                borderRadius: '4px',
                                padding: '3px 10px',
                                fontSize: '16px',
                                fontWeight: '350',
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                              }}
                              value={bufferTimeAfter}
                              onChange={e => {
                                setFieldValue('bufferTimeAfter', e.target.value);
                              }}
                              fullWidth
                              items={bufferTimeValues?.map(({ title, value }) => ({
                                title,
                                value,
                              }))}
                              disabled={!isAfterBufferActive}
                              error=""
                            />
                          </Grid>
                          {/* <Grid
                            md={5}
                            sm={6}
                            xs={12}
                            style={{ padding: '12px', display: 'flex', alignItems: 'flex-end' }}
                          >
                            <StyledHeading GiveYourself mobileView={mobileView}>
                              Give yourself buffer time before or after your client bookings{' '}
                            </StyledHeading>
                          </Grid> */}
                        </Grid>
                      </CustomExpansionPanel>
                      <CustomExpansionPanel
                        summaryTitle={
                          <div className="d-flex align-items-center">
                            <p
                              style={{
                                fontFamily: 'Avenir',
                                fontSize: mobileView ? '14px' : '16px',
                                fontWeight: '800',
                                lineHeight: '21px',
                                letterSpacing: '0.1',
                                marginBottom: '0px',
                                marginRight: '5px',
                              }}
                            >
                              Customize Start Time Increments?
                            </p>
                            <Tooltip
                              title="This value is used as interval for calendar slots"
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon
                                htmlColor={colors.lightBrown}
                                style={{ cursor: 'pointer', marginLeft: '8px' }}
                              />
                            </Tooltip>
                          </div>
                        }
                        summarySubTitle={
                          startIncrementDurationsOneToOne?.find(d => d.value === startTimeIncrementDuration)?.title
                        }
                        expand={false}
                        mobileView={mobileView}
                        noBorder
                      >
                        <Grid item md={4} sm={4} xs={12}>
                          <SelectStyled
                            style={{
                              fontFamily: 'Avenir',
                              border: '1px solid #DFE3E4',
                              borderRadius: '4px',
                              fontSize: '16px',
                              fontWeight: '350',
                              lineHeight: '24px',
                              letterSpacing: '0.15px',
                            }}
                            onChange={onChangeStartTimeIncrementDuration}
                            value={startTimeIncrementDuration}
                            fullWidth
                            items={startIncrementDurationsOneToOne
                              .filter(k => k.value <= sessionDuration)
                              .map(({ title, value }) => ({
                                title,
                                value,
                              }))}
                            disabled={false}
                            error={startTimeIncrementDurationError}
                            errorHeight={startTimeIncrementDurationError ? 50 : 0}
                          />
                        </Grid>
                      </CustomExpansionPanel>

                      <CustomExpansionPanel
                        summaryTitle={
                          <div className="d-flex align-items-center">
                            <p
                              style={{
                                fontFamily: 'Avenir',
                                fontSize: mobileView ? '14px' : '16px',
                                fontWeight: '800',
                                lineHeight: '21px',
                                letterSpacing: '0.1',
                                marginBottom: '0px',
                                marginRight: '5px',
                              }}
                            >
                              Block Single Days?
                            </p>
                            <Tooltip
                              title="Now you can Block any specific days you want."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon
                                htmlColor={colors.lightBrown}
                                style={{ cursor: 'pointer', marginLeft: '8px' }}
                              />
                            </Tooltip>
                          </div>
                        }
                        noBorder
                        expand={isBlockSingleDayExpanded}
                        setMainExpand={setIsBlockSingleDayExpanded}
                        mobileView={mobileView}
                      >
                        <Grid container style={{ width: '100%' }}>
                          <div id="blockSingleDayDiv" style={{ display: 'flex', width: '100%' }}>
                            <div style={{ width: mobileView ? '100%' : '45%' }}>
                              <Calendar
                                className="custom-blockdaycalendar"
                                style={{ width: '100%', boxShadow: 'none' }}
                                value={newBlockedDates}
                                monthYearSeparator={' '}
                                headerOrder={['MONTH_YEAR', 'LEFT_BUTTON', 'RIGHT_BUTTON']}
                                onChange={val => {
                                  const newBlockdates = val.map(date => new Date(date.unix * 1000));
                                  const reversedDates = [...newBlockdates]?.reverse();
                                  setNewBlockedDates(reversedDates);
                                  setBlockedDates(reversedDates?.map(date => date.toISOString()));
                                }}
                                multiple
                                minDate={moment(oneToOneStartDate).toDate()}
                                maxDate={moment(oneToOneEndDate).toDate()}
                                renderButton={(direction, handleClick, disabled) => (
                                  <div
                                    style={{
                                      cursor: disabled ? 'default' : 'pointer',
                                      color: disabled ? 'gray' : '',
                                      fontWeight: '600',
                                      paddingRight: direction === 'right' ? '5px' : '',
                                    }}
                                    onClick={handleClick}
                                  >
                                    {direction === 'right' ? (
                                      <SvgIcon style={{ width: '20px', height: '20px' }} viewBox="0 0 29 29">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="29"
                                          height="29"
                                          viewBox="0 0 29 29"
                                          fill="none"
                                        >
                                          <path
                                            d="M9.16699 6.92943L16.7368 14.5168L9.16699 22.1019L11.4966 24.4334L21.4133 14.5168L11.4966 4.6001L9.16699 6.92943Z"
                                            fill={disabled ? 'gray' : colors.darkOceanBlue}
                                          />
                                        </svg>
                                      </SvgIcon>
                                    ) : (
                                      <SvgIcon style={{ width: '20px', height: '20px' }} viewBox="0 0 29 29">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="29"
                                          height="29"
                                          viewBox="0 0 29 29"
                                          fill="none"
                                        >
                                          <path
                                            d="M20.333 6.92943L12.7632 14.5168L20.333 22.1019L18.0034 24.4334L8.08674 14.5168L18.0034 4.6001L20.333 6.92943Z"
                                            fill={disabled ? 'gray' : colors.darkOceanBlue}
                                          />
                                        </svg>
                                      </SvgIcon>
                                    )}
                                  </div>
                                )}
                                weekStartDayIndex={1}
                                plugins={[
                                  <DatePanel
                                    header="Blocked Days"
                                    formatFunction={({ format }) => {
                                      return moment(format).format(DATE_FORMATS.DAY);
                                    }}
                                    position={mobileView ? 'bottom' : 'right'}
                                    reverse
                                  />,
                                ]}
                              />
                            </div>
                          </div>
                          {/* <div style={{ position: 'relative', width: '100%' }}>
                            <DatePicker
                              ref={calenderRef}
                              value={newBlockedDates}
                              onChange={val => setNewBlockedDates(val.map(date => new Date(date.unix * 1000)))}
                              multiple
                              minDate={moment(oneToOneStartDate).toDate()}
                              maxDate={moment(oneToOneEndDate).toDate()}
                              sort
                              highlightToday={false}
                              onClose={() => {
                                setBlockedDates(newBlockedDates?.map(date => date.toISOString()));
                              }}
                              plugins={[
                                <DatePanel
                                  header="Blocked Days"
                                  formatFunction={({ format }) => {
                                    return moment(format).format(DATE_FORMATS.DAY);
                                  }}
                                />,
                              ]}
                            />
                            <StyledLink
                              style={{
                                position: 'absolute',
                                left: 0,
                                top: '0',
                                backgroundColor: 'white',
                                // width: '247px',
                                minWidth: '200px',
                              }}
                              onClick={e => {
                                e.preventDefault();
                                calenderRef.current.openCalendar();
                              }}
                            >
                              <Icon path={mdiPlus} size={1} />
                              Block Single Days ({blockedDates?.length || 0})
                            </StyledLink>
                          </div> */}
                        </Grid>
                      </CustomExpansionPanel>

                      <CustomExpansionPanel
                        summaryTitle={
                          <div className="d-flex align-items-center">
                            <p
                              style={{
                                fontFamily: 'Avenir',
                                fontSize: '16px',
                                fontWeight: '800',
                                lineHeight: '21px',
                                letterSpacing: '0.1',
                                marginBottom: '0px',
                              }}
                            >
                              Minimum Notice
                            </p>
                            <Tooltip
                              title="Set the minimum amount of notice that is required. The clients can’t schedule within the minimum notice duration of a session start time."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon
                                htmlColor={colors.lightBrown}
                                style={{ cursor: 'pointer', marginLeft: '8px' }}
                              />
                            </Tooltip>
                            <div
                              style={{
                                fontFamily: 'Avenir',
                                fontSize: '16px',
                                fontWeight: '350',
                                lineHeight: '21px',
                                letterSpacing: '0.1',
                                marginBottom: '0px',
                                marginLeft: '8px',
                              }}
                            >
                              {minimumNotice?.value == 0
                                ? `None`
                                : `${minimumNotice?.value} ${
                                    minimumNotice?.value == 1 ? minimumNotice?.type.slice(0, -1) : minimumNotice?.type
                                  }`}
                            </div>
                          </div>
                        }
                        expand={minNoticeExpand}
                        mobileView={mobileView}
                        noBorder
                      >
                        <div
                          style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}
                        >
                          <div>The clients can’t schedule within</div>
                          <TextField
                            variant="outlined"
                            value={minimumNotice?.value}
                            InputProps={{
                              inputProps: {
                                style: {
                                  fontSize: '16px',
                                  fontFamily: 'Avenir',
                                  padding: '13px 0px 13px 13px',
                                  fontWeight: '350',
                                  lineHeight: '24px',
                                  maxWidth: '26px',
                                  color: '#000000DE',
                                },
                              },
                            }}
                            onChange={e => {
                              setFieldValue('minimumNotice', { ...minimumNotice, value: e.target.value });
                            }}
                          />
                          <Select
                            style={{
                              fontFamily: 'Avenir',
                              border: '1px solid #DFE3E4',
                              borderRadius: '4px',
                              padding: '3px 10px',
                              fontSize: '16px',
                              fontWeight: '350',
                              lineHeight: '24px',
                              letterSpacing: '0.15px',
                              marginTop: '3px',
                            }}
                            labelTop={mobileView ? '32px' : '24px'}
                            name="minimumNotice"
                            variant="Outlined"
                            value={minimumNotice?.type}
                            onChange={e => {
                              setFieldValue('minimumNotice', { ...minimumNotice, type: e.target.value });
                            }}
                            items={durationUnitsValues?.map(({ title, value }) => ({
                              title,
                              value,
                            }))}
                          />
                          <div>of a session start time</div>
                        </div>
                      </CustomExpansionPanel>

                      <CustomExpansionPanel
                        summaryTitle={
                          <div className="d-flex align-items-center">
                            <p
                              style={{
                                fontFamily: 'Avenir',
                                fontSize: mobileView ? '14px' : '16px',
                                fontWeight: '800',
                                lineHeight: '21px',
                                letterSpacing: '0.1',
                                marginBottom: '0px',
                                marginRight: '10px',
                              }}
                            >
                              Auto-Mark Sessions Complete?
                            </p>
                            <Tooltip
                              title="If enabled, your sessions are automatically marked complete 1 hour after the completion time. If disabled, you need to manually mark your sessions complete."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </div>
                        }
                        // summarySubTitle={giveTitle(bufferTimeBefore, bufferTimeAfter)}
                        expand={false}
                        mobileView={mobileView}
                        subTitleBelowTheTitle={true}
                        noBorder
                      >
                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} sm={4} xs={6}>
                          <Checkbox
                            checked={values?.markSessionsAutoComplete === 'true' ? true : false}
                            onChange={() => {
                              if (values?.markSessionsAutoComplete === 'true') {
                                setFieldValue('markSessionsAutoComplete', 'false');
                              } else {
                                setFieldValue('markSessionsAutoComplete', 'true');
                              }
                            }}
                            color="primary"
                          />
                          <p style={{ marginTop: '0px', marginBottom: '0px' }}>Auto Mark Session Complete </p>
                        </Grid>
                      </CustomExpansionPanel>
                      <CustomExpansionPanel
                        summaryTitle={
                          <div className="d-flex align-items-center">
                            <p
                              style={{
                                fontFamily: 'Avenir',
                                fontSize: mobileView ? '14px' : '16px',
                                fontWeight: '800',
                                lineHeight: '21px',
                                letterSpacing: '0.1',
                                marginBottom: '0px',
                                marginRight: '10px',
                              }}
                            >
                              Apply Scheduling Details to Other 1:1 Services?
                            </p>
                          </div>
                        }
                        // summarySubTitle={giveTitle(bufferTimeBefore, bufferTimeAfter)}
                        expand={false}
                        mobileView={mobileView}
                        subTitleBelowTheTitle={true}
                        noBorder
                      >
                        <Grid item md={12} sm={12} xs={12}>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                fontFamily: 'Avenir',
                                fontSize: '16px',
                                fontWeight: '500',
                              }}
                            >
                              Services
                            </div>
                            {allcontributionList.length != values.selectedContributionIdForCopySettings.length ? (
                              <div
                                onClick={() => {
                                  setFieldValue('selectedContributionIdForCopySettings', allcontributionList);
                                }}
                                style={{
                                  fontFamily: 'Avenir',
                                  fontSize: '16px',
                                  fontWeight: '900',
                                  cursor: 'pointer',
                                }}
                              >
                                Select All
                              </div>
                            ) : (
                              <div
                                onClick={() => {
                                  setFieldValue('selectedContributionIdForCopySettings', []);
                                }}
                                style={{
                                  fontFamily: 'Avenir',
                                  fontSize: '16px',
                                  fontWeight: '900',
                                  cursor: 'pointer',
                                }}
                              >
                                Clear All
                              </div>
                            )}
                          </div>
                          <SelectStyled
                            label=""
                            name="selectedContributionIdForCopySettings"
                            fullWidth
                            multiple
                            style={{
                              fontFamily: 'Avenir',
                              border: '1px solid #DFE3E4',
                              borderRadius: '4px',
                              fontSize: '16px',
                              fontWeight: '350',
                              lineHeight: '24px',
                              letterSpacing: '0.15px',
                            }}
                            items={contributionList}
                          />
                          <div style={{ width: '100', display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
                            <Button
                              onClick={() => {
                                set0penApplySettingsModal(true);
                                filterContribution(contributionList, values?.selectedContributionIdForCopySettings);
                              }}
                              style={{
                                fontFamily: 'Avenir',
                                fontWeight: '900',
                                fontSize: '14px',
                                padding: '12px 16px 12px 16px',
                              }}
                              type="button"
                              autoWidth
                              disabled={values?.selectedContributionIdForCopySettings.length === 0}
                            >
                              Apply Settings and Save
                            </Button>
                          </div>
                        </Grid>
                      </CustomExpansionPanel>
                    </div>
                  </Grid>
                </Grid>
              </CustomExpansionPanel>
              <CustomExpansionPanel
                summaryTitle={'Calendar Preview of Session Availability'}
                mobileView={mobileView}
                expand={true}
                customStyle={mobileView ? { padding: '0px' } : {}}
              >
                <div className="master-calendar-container" style={{ padding: mobileView ? '0px' : '' }}>
                  <CustomCalendar
                    values={preparedValues}
                    loadingTimes={loadingTimes}
                    hideSubTitle
                    CustomToolbar={CustomToolbar}
                    setBlockedDates={setBlockedDates}
                    newBlockedDates={newBlockedDates}
                    setNewBlockedDates={setNewBlockedDates}
                    openAndScrollToBlockCalendar={openAndScrollToBlockCalendar}
                    oneToOneStartDate={oneToOneStartDate}
                    oneToOneEndDate={oneToOneEndDate}
                    mobileView={mobileView}
                    calendarActiveView={calendarActiveView}
                    incrementNewSlots={incrementNewSlots}
                  />
                </div>
              </CustomExpansionPanel>
              {/* <p
                style={{
                  fontFamily: 'Avenir',
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '30px',
                  letterSpacing: '0.1',
                }}
              >
                Advance Settings
              </p> */}
              {/* <CustomExpansionPanel
                summaryTitle="What are the communication details?"
                summarySubTitle="Calendar Invite, Automated Email Reminders, Automated SMS Reminders"
                mobileView={mobileView}
                expand={true}
              >
                <Grid container>
                  <Grid item sm={12} xs={12} style={{ width: '100%' }}>
                    <div
                      style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                      <div style={{ fontFamily: 'Avenir', fontWeight: '500', fontSize: '16px' }}>
                        Automated Email Reminders
                      </div>
                      <div>
                        <SlimSwitch className="switch">
                          <StyledInput
                            // disabled={question.isPhoneNumberAdded}
                            type="checkbox"
                            onClick={() => {
                              setEmailReminder(!emailReminder);
                            }}
                            checked={emailReminder}
                          />
                          <SlimStyledSlider className="slider round" />
                        </SlimSwitch>
                      </div>
                    </div>
                    <div style={{ width: '100%', margin: mobileView ? '15px 0px' : '' }}>
                      An invitee will receive a reminder email before a scheduled session at specified times.
                    </div>
                  </Grid>
                  {emailReminder && (
                    <Grid sm={12} xs={12} style={{ width: '100%', marginTop: '10px' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <FormControlLabel
                            checked={true}
                            onChange={() => {}}
                            control={<Checkbox color="primary" />}
                            label="1 hour reminder"
                            name="onehourreminder"
                          />
                        </div>
                        <div>
                          <MUIButton
                            variant="outlined"
                            sx={{
                              color: colors.darkOceanBlue,
                              borderColor: colors.darkOceanBlue,
                              textTransform: 'none',
                              '&:hover': {
                                color: colors.darkOceanBlue,
                                borderColor: colors.darkOceanBlue,
                              },
                            }}
                            startIcon={<EditIcon htmlColor={colors.darkOceanBlue} />}
                            onClick={() => {}}
                          >
                            Edit
                          </MUIButton>
                        </div>
                      </div>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <FormControlLabel
                            checked={true}
                            onChange={() => {}}
                            control={<Checkbox color="primary" />}
                            label="24 hour reminder"
                            name="fulldayhourreminder"
                          />
                        </div>
                        <div>
                          <MUIButton
                            variant="outlined"
                            sx={{
                              color: colors.darkOceanBlue,
                              borderColor: colors.darkOceanBlue,
                              textTransform: 'none',
                              '&:hover': {
                                color: colors.darkOceanBlue,
                                borderColor: colors.darkOceanBlue,
                              },
                            }}
                            startIcon={<EditIcon htmlColor={colors.darkOceanBlue} />}
                            onClick={() => {}}
                          >
                            Edit
                          </MUIButton>
                        </div>
                      </div>
                    </Grid>
                  )}
                  <Grid sm={12} xs={12} style={{ width: '100%', marginTop: '15px' }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ fontFamily: 'Avenir', fontWeight: '500', fontSize: '16px' }}>
                        Automated SMS Reminders
                      </div>
                      <div>
                        <SlimSwitch className="switch">
                          <StyledInput
                            // disabled={question.isPhoneNumberAdded}
                            type="checkbox"
                            onClick={() => {
                              setSmsReminder(!smsReminder);
                            }}
                            checked={smsReminder}
                          />
                          <SlimStyledSlider className="slider round" />
                        </SlimSwitch>
                      </div>
                    </div>
                    <div style={{ width: '100%', margin: mobileView ? '15px 0px' : '' }}>
                      Your invitees will have the option of receiving SMS session reminders before a scheduled session
                      at specified times.
                    </div>
                  </Grid>
                  {smsReminder && (
                    <Grid sm={12} xs={12} style={{ width: '100%', marginTop: '10px' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <FormControlLabel
                            checked={true}
                            onChange={() => {}}
                            control={<Checkbox color="primary" />}
                            label="1 hour reminder"
                            name="onehourreminder"
                          />
                        </div>
                        <div>
                          <MUIButton
                            variant="outlined"
                            sx={{
                              color: colors.darkOceanBlue,
                              borderColor: colors.darkOceanBlue,
                              textTransform: 'none',
                              '&:hover': {
                                color: colors.darkOceanBlue,
                                borderColor: colors.darkOceanBlue,
                              },
                            }}
                            startIcon={<EditIcon htmlColor={colors.darkOceanBlue} />}
                            onClick={() => {}}
                          >
                            Edit
                          </MUIButton>
                        </div>
                      </div>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <FormControlLabel
                            checked={true}
                            onChange={() => {}}
                            control={<Checkbox color="primary" />}
                            label="24 hour reminder"
                            name="fulldayhourreminder"
                          />
                        </div>
                        <div>
                          <MUIButton
                            variant="outlined"
                            sx={{
                              color: colors.darkOceanBlue,
                              borderColor: colors.darkOceanBlue,
                              textTransform: 'none',
                              '&:hover': {
                                color: colors.darkOceanBlue,
                                borderColor: colors.darkOceanBlue,
                              },
                            }}
                            startIcon={<EditIcon htmlColor={colors.darkOceanBlue} />}
                            onClick={() => {}}
                          >
                            Edit
                          </MUIButton>
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </CustomExpansionPanel> */}

              <Grid sm={12} xs={12} style={{ width: '100%' }}>
                <Formik
                  innerRef={customQuestionsFormRef}
                  initialValues={{
                    enrollmentForm: {
                      isCustomEnrollmentFormAdded: contribution?.isCustomEnrollmentFormAdded,
                      isEditMode,
                      enrollmentFormViewModel: {
                        isCustomEnrollmentFormAdded: contribution?.isCustomEnrollmentFormAdded,
                        isEditMode,
                        contributionId: contribution?.id,
                        contributionName: contribution?.title,
                        userId: user?.id,
                        questions:
                          contribution?.enrollmentFormViewModel?.questions.map(question => {
                            delete question.userResponse;
                            return { ...question, isSaved: true };
                          }) ?? [],
                      },
                    },
                  }}
                  validationSchema={enrollmentFormSchema}
                  onSubmit={() => {
                    console.log('date');
                  }}
                >
                  {formProps => {
                    const questionsCount =
                      formProps?.values?.enrollmentForm?.enrollmentFormViewModel?.questions?.length;
                    return (
                      <Grid sm={12} xs={12} style={{ width: '100%' }}>
                        <CustomExpansionPanel
                          summaryTitle={
                            <div className="d-flex align-items-center">
                              <p
                                style={{
                                  marginBottom: '0px',
                                  marginRight: '5px',
                                }}
                              >
                                Custom Client Enrollment Form
                              </p>
                              <Tooltip
                                title="This will add custom fields for your clients to answer additional questions on your enrollment page."
                                arrow
                                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                              >
                                <InfoIcon
                                  htmlColor={colors.lightBrown}
                                  style={{ cursor: 'pointer', marginLeft: '0px 8px' }}
                                />
                              </Tooltip>
                            </div>
                          }
                          summarySubTitle={`${
                            questionsCount === 0
                              ? 'No questions added yet'
                              : questionsCount === 1
                              ? `${questionsCount} Question added`
                              : questionsCount > 1 && `${questionsCount} Questions added`
                          }`}
                          mobileView={mobileView}
                          expand={false}
                        >
                          <CustomQuestions
                            customQuestionsFormRef={customQuestionsFormRef}
                            className=""
                            customEnrollmentFormRef={customEnrollmentFormRef}
                            values={formProps?.values}
                            isPhoneNumberAdded={values?.isPhoneNumberAdded}
                            isCoachNumberAdded={values?.isCoachNumberAdded}
                            handleSubmit={formProps?.handleSubmit}
                          />
                        </CustomExpansionPanel>
                      </Grid>
                    );
                  }}
                </Formik>
              </Grid>
              <LiveSessionReminders
                values={smsReminderSettings}
                setFieldValue={setFieldValue}
                contributionType={ContributionType.contributionOneToOne}
              />
              {/* <Grid container spacing={4}>
                <Grid item container md={6} xs={12}>
                  <Grid item md={12} xs={12}>
                    <FormControl>
                      <FormLabel
                        style={{ fontSize: '16px', color: 'black', fontFamily: 'Brandon Text' }}
                        id="demo-row-radio-buttons-group-label"
                      >
                        How do you want to connect with your clients?
                      </FormLabel>
                      <RadioGroup
                        row
                        name="connection_option"
                        onChange={({ target }) => {
                          if (target.value === 'phone-call') {
                            setFieldValue('isPhoneNumberAdded', true);
                            setPhoneNumberOption(
                              values?.isCoachNumberAdded
                                ? PhoneCallOptions.coachPhone.value
                                : PhoneCallOptions.clientsPhone.value,
                            );
                          } else {
                            setFieldValue('isPhoneNumberAdded', false);
                          }
                          setConnectionOption(target.value);
                        }}
                        value={connectionOption}
                      >
                        <div className="d-flex" style={{ gap: '16px' }}>
                          <div className="d-flex align-items-center">
                            <FormControlLabel
                              style={{ marginBottom: 0, marginRight: '10px' }}
                              value="live-video"
                              control={<CustomRadio />}
                              label="Live Video"
                            />
                            <Tooltip
                              title="If you want to meet with your clients online using Cohere video or Zoom, select this option"
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </div>
                          <div className="d-flex align-items-center">
                            <FormControlLabel
                              style={{ marginBottom: 0, marginRight: '10px' }}
                              value="phone-call"
                              control={<CustomRadio />}
                              label="Phone Call"
                            />
                            <Tooltip
                              title="If you prefer to meet with your clients over the phone, select this option"
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>

                    {connectionOption === 'live-video' && (
                      <Select
                        style={{ fontFamily: 'Brandon Text' }}
                        name="liveVideoServiceProvider.providerName"
                        onChange={({ target }) => {
                          setFieldValue(target.name, target.value);
                          if (target.value === 'Cohere') {
                            setFieldValue('customInvitationBody', customInvitaionBodyText.cohereVideo);
                          } else if (target.value === 'Zoom') {
                            setFieldValue('customInvitationBody', customInvitaionBodyText.zoomVideo);
                          } else if (target.value === 'Custom') {
                            setFieldValue('customInvitationBody', customInvitaionBodyText.privateLink);
                          } else {
                            setFieldValue('customInvitationBody', customInvitaionBodyText.inPerson);
                          }
                        }}
                        fullWidth
                        items={liveVideoProviders}
                      />
                    )}

                    {connectionOption === 'phone-call' && (
                      <>
                        <Select
                          style={{ fontFamily: 'Brandon Text' }}
                          name="isPhoneNumberAdded"
                          value={phoneNumberOption}
                          onChange={({ target
                            setPhoneNumberOption(target.value);
                            setFieldValue('isCoachNumberAdded', target.value === PhoneCallOptions.coachPhone.value);
                          }}
                          fullWidth
                          items={phoneCallOptions}
                        />

                        {phoneNumberOption === PhoneCallOptions.coachPhone.value && (
                          <>
                            <StyledPhoneInput
                              value={values?.coachPhoneNumber}
                              className={`my-2 coach-phone ${
                                getIn(errors, 'coachPhoneNumber') && getIn(touched, 'coachPhoneNumber')
                                  ? 'phone-error'
                                  : ''
                              }`}
                              onChange={phone => {
                                setFieldValue(`coachPhoneNumber`, phone);
                              }}
                            />
                            {getIn(errors, 'coachPhoneNumber') && getIn(touched, 'coachPhoneNumber') && (
                              <span className="text-danger">Enter a valid phone number</span>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Select
                      style={{ fontFamily: 'Brandon Text' }}
                      label={<div style={{ fontFamily: 'Brandon Text' }}>How long are your sessions?</div>}
                      onChange={onChangeSessionDuration}
                      value={sessionDuration}
                      fullWidth
                      items={durationsOneToOne.map(({ title, value }) => ({
                        title,
                        value,
                      }))}
                      disabled={alreadyBooked}
                      error={sessionDurationError}
                      errorHeight={sessionDurationError ? 50 : 0}
                    />
                  </Grid>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Input
                    hidden={
                      values.liveVideoServiceProvider &&
                      values.liveVideoServiceProvider.providerName !== LiveVideoProvider.Custom.value
                    }
                    placeholder="Enter Private Link"
                    style={{ fontFamily: 'Brandon Text' }}
                    name="liveVideoServiceProvider.customLink"
                    onChange={({ target }) => {
                      setFieldValue(target.name, target.value, false);
                    }}
                    fullWidth
                    label={
                      <div style={{ fontFamily: 'Brandon Text' }}>
                        Use Your Private Room link instead (Zoom or Others)
                        <Tooltip
                          style={{ fontFamily: 'Brandon Text' }}
                          title="Cohere provides a built-in rich & streamlined video experience for you and your clients.
                          The Cohere video experiences saves your in-video chat threads, private session notes, and automates video recordings.
                          If you instead wish to use another video provider witho0ut these features,
                          you can add your private room link below and we’ll make sure your clients show up there instead.
                          When you and your clients access this Contribution,
                          the session launch button will open your platform of choice and we’ll also update calendar invites for both you and your clients."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <StyledInfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                        </Tooltip>
                      </div>
                    }
                  />
                  <Input
                    hidden={
                      values.liveVideoServiceProvider &&
                      values.liveVideoServiceProvider.providerName !== LiveVideoProvider.InPersonSession.value
                    }
                    placeholder="Enter Private Link"
                    name="liveVideoServiceProvider.customLink"
                    onChange={({ target }) => {
                      setFieldValue(target.name, target.value, false);
                    }}
                    fullWidth
                    label={
                      <div style={{ fontFamily: 'Brandon Text' }}>
                        Please add meeting location
                        <Tooltip
                          title="This is the meeting location of your sessions. Feel free to link an address, video conferencing link, etc."
                          arrow
                          style={{ fontFamily: 'Brandon Text' }}
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <StyledInfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                        </Tooltip>
                      </div>
                    }
                  />
                  <div
                    style={{
                      marginTop: '4px',
                      padding: '5px',
                      width: '100%',
                      height: '100%',
                      fontFamily: 'Brandon Text',
                    }}
                  >
                    <div>
                      Calendar invite body text
                      <Tooltip
                        style={{ fontFamily: 'Brandon Text' }}
                        title="This is the meeting location of your sessions. Feel free to link an address, video conferencing link, etc."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <StyledInfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                      </Tooltip>
                    </div>
                    <TextArea
                      style={{ fontFamily: 'Brandon Text' }}
                      labelName="CustomInvitationBody"
                      placeholder="This section is used to share a short bio. This is an opportunity to include your professional title, background, experience and your mission."
                      name="customInvitationBody"
                      rows="5"
                      fullWidth
                      helperTextPosition="right"
                      id="contribution-details-bio-textarea"
                    />
                  </div>
                </Grid>
              </Grid> */}
              {
                <FieldArray name="sessions">
                  {({ push, remove }) => {
                    const addHandler = () => {
                      push({
                        sessionTimes: [],
                        durations: values?.sessions[0]?.durations || [60],
                        minParticipantsNumber: 1,
                        maxParticipantsNumber: 1,
                        title: '',
                      });
                    };

                    const removeHandler = id => {
                      remove(id);
                    };

                    return (
                      <>
                        {values.sessions.map((session, index) => (
                          <Session
                            /* eslint-disable-next-line */
                            key={index}
                            index={index}
                            timesCount={session?.sessionTimes?.length || 0}
                            oneToOne
                            onDelete={removeHandler}
                            otherEvents={otherEvents}
                            alreadyBooked={alreadyBooked}
                            values={values}
                            newSlots={newSlots}
                          />
                        ))}
                        {values?.sessions?.length === 0 && (
                          <Button style={{ fontFamily: 'Brandon Text' }} type="button" onClick={addHandler} autoWidth>
                            Add Availabillity
                          </Button>
                        )}
                      </>
                    );
                  }}
                </FieldArray>
              }
              {/* <Calendar values={preparedValues} loadingTimes={loadingTimes} /> */}
            </CardBody>
          </Card>
        </Grid>
      </Grid>
      {(showExternalCalendarSettingsModal ||
        showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
        showExternalCalendarConnectionCompleteStepsModal.showStep2) && (
        <ExternalCalendarSettingsPopup
          isModalOpen={
            showExternalCalendarSettingsModal ||
            showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
            showExternalCalendarConnectionCompleteStepsModal.showStep2
          }
          // connectedCalendars={calendars}
          connectedCalendar={calendarForSettingsPopup}
          onCloseModal={() => {
            setCalendarForSettingsPopup(null);
            setShowExternalCalendarSettingsModal(false);
            setShowExternalCalendarConnectionCompleteStepsModal({
              ...showExternalCalendarConnectionCompleteStepsModal,
              showStep1: false,
              showStep2: false,
            });
          }}
          setShowExternalCalendarConnectionCompleteStepsModal={setShowExternalCalendarConnectionCompleteStepsModal}
          showExternalCalendarConnectionCompleteStepsModal={showExternalCalendarConnectionCompleteStepsModal}
          // onConfirm={onRescheduleSlotSelection}
          isOneToOneContributionPage
          setExternalCalendarSetToDefault={setExternalCalendarSetToDefault}
        />
      )}
      {isAddCalendarModalOpen && (
        <AddCalendarModal
          isOpen
          onModalClose={handleCloseAddCalendarModal}
          onSubmit={handleModalSubmit}
          calendars={calendars}
          addCalendar={addCalendar}
          contributionId={query.id}
          isRemoveEmailCalendar={isRemoveEmailCalendar}
          setIsRemoveEmailCalendar={setIsRemoveEmailCalendar}
          isCreate={isCreate}
          lastAddedMail={lastAddedMail}
          loadingLastEmail={loadingLastEmail}
          values={values}
          setSelectedEmailCalendar={setSelectedEmailCalendar}
          selectedEmailCalendar={selectedEmailCalendar}
          setFieldValue={setFieldValue}
          showsetInviteCalendarRow="true"
          integrationPage={false}
          isExternalCalendarDisconnected={values.isExternalCalendarDisconnected}
          setCalendarForSettingsPopup={setCalendarForSettingsPopup}
          setShowExternalCalendarSettingsModal={setShowExternalCalendarSettingsModal}
          title="Manage Calendars"
          isOneToOneStep3Page
        />
      )}
      {isSendInvitesFromModalOpen && (
        <SendInvitesFromModal
          isOpen={isSendInvitesFromModalOpen}
          onModalClose={handleCloseSendInvitesFromModalOpen}
          onSubmit={email => {
            setSelectedEmailCalendar(email);
            handleCloseSendInvitesFromModalOpen();
          }}
          setFieldValue={setFieldValue}
          values={values}
          // onSubmit={handleModalSubmit}
          // calendars={calendars}
          // addCalendar={addCalendar}
          // contributionId={query.id}
          // isCreate={isCreate}
          // lastAddedMail={lastAddedMail}
          // loadingLastEmail={loadingLastEmail}
          isGroup={false}
        />
      )}
      {removedCalendarEmail && (
        <RemoveCalendarModal
          isOpen={removedCalendarEmail}
          onCancel={handleCloseRemoveCalendarModal}
          onSubmit={onRemovecalendarSubmit}
        />
      )}
      <AvailabilityModal
        showTerms={showAvailabilityPopUp}
        onCancel={() => {
          setshowAvailabilityPopUp(false);
        }}
        setshowAvailabilityPopUp={setshowAvailabilityPopUp}
        selectedDate={null}
        selectedContribution={{
          label: contribution?.title || activeContribution.title,
          id: contribution?.id || activeContribution.id,
        }}
        incrementNewSlots={incrementNewSlots}
      />
      <ZoomDefaultProviderModal
        onSubmit={() => {
          setFieldValue('isZoomDefaultServiceProvider', true);
          setShowTerms(false);
        }}
        showTerms={showTerms}
        onCancel={() => {
          setFieldValue('isZoomDefaultServiceProvider', true);
          setShowTerms(false);
        }}
      />
      <Modal
        disableOverlayClick={true}
        title="Are you sure you want to apply these settings?"
        isOpen={openApplySettingsModal}
        isCreatingContribution={true}
        brandingColor={false}
        submitTitle="Yes"
        cancelTitle="Cancel"
        shrinkCancelButton={true}
        cancelBtnStyle={{ padding: '10px 20px !important' }}
        headerBorder={true}
        onSubmit={() => {
          set0penApplySettingsModal(!openApplySettingsModal);
          setFieldValue('copySchedulingSettings', true);
          let allContributions = [];
          let allList = selectedArrayForModal.map(d => {
            return allContributions.push(d.id);
          });
          setFieldValue('selectedContributionIdForCopySettings', allContributions);
        }}
        onCancel={togglePopup}
      >
        <div style={{ fontFamily: 'Avenir', fontWeight: '400', fontSize: '18px', marginTop: '10px' }}>
          {/* You are changing your scheduling details for the following 1:1 services. Do you wish to proceed? */}
          Select any additional 1:1 services that you’d like to reflect the same scheduling availability.
        </div>
        <div style={{ fontFamily: 'Avenir', fontWeight: '400', fontSize: '18px', marginTop: '5px' }}>
          {/* The settings for parent-level preferences such as the type of 1:1 sessions, their durations, and also start
          time increments can not be transferred over. You will need to set up these preferences separately. */}
          The preferences selected on your other services, such as their durations, start time increments, etc, will not
          be changed. If you want to change these, please do so separately.
        </div>
        {/* <div style={{ fontFamily: 'Avenir', fontWeight: '400', fontSize: '18px', marginTop: '5px' }}>
          Please note that by applying, the settings for the contributions below will be overridden.
        </div> */}
        <ul>
          {arrayForModal?.map(item => {
            return (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Checkbox
                  color="primary"
                  checked={selectedArrayForModal.find(obj => obj.value === item.value)}
                  onChange={e => {
                    if (selectedArrayForModal.find(obj => obj.value === item.value)) {
                      setSelectedArrayForModal(selectedArrayForModal.filter(obj => obj.value !== item.value));
                    } else {
                      addObjectIfNotExists(arrayForModal, item);
                    }
                  }}
                />
                <div
                  style={{
                    fontFamily: 'Avenir',
                    fontWeight: '400',
                    fontSize: '16px',
                    alignContent: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  {item.title}
                </div>
              </div>
            );
          })}
        </ul>
      </Modal>
    </Form>
  );
};

OneToOneFormComponent.propTypes = {
  values: PropTypes.shape({
    sessions: PropTypes.arrayOf(PropTypes.shape()),
    isPhoneNumberAdded: PropTypes.bool.isRequired,
    isCoachNumberAdded: PropTypes.bool.isRequired,
    enrollmentForm: PropTypes.shape({
      enrollmentFormViewModel: shape({
        questions: shape([]),
      }),
    }).isRequired,
  }).isRequired,
  otherEvents: PropTypes.arrayOf(PropTypes.shape()),
  alreadyBooked: PropTypes.bool,
  setAddCustomEnrollmentQuestions: PropTypes.func.isRequired,
  customQuestionsFormRef: PropTypes.shape({
    values: PropTypes.shape({
      enrollmentForm: PropTypes.shape({
        enrollmentFormViewModel: PropTypes.shape({
          questions: PropTypes.shape([]),
        }),
      }),
    }),
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  errors: PropTypes.shape([]).isRequired,
  touched: PropTypes.shape([]).isRequired,
  CustomInvitationBodyDefault: PropTypes.string.isRequired,
};

OneToOneFormComponent.defaultProps = {
  otherEvents: [],
  alreadyBooked: false,
};

const mapStateToProps = ({ oneToOneData, contributions, calendars }) => ({
  oneToOneData,
  calendars: calendars.calendarsAccounts,
  lastAddedMail: calendars.lastAddedEmail,
  loadingLastEmail: calendars.loadingLastEmail,
  accountsForConflictsCheck: calendars.accountsForConflictsCheck,
  contribution: contributions.activeContribution,
  loadingTimes: contributions.loadingTimes,
  sessionDuration: oneToOneData?.sessionDuration,
  sessionDurationError: oneToOneData?.sessionDurationError,
  startTimeIncrementDuration: oneToOneData?.startTimeIncrementDuration,
  startTimeIncrementSlots: oneToOneData?.startTimeIncrementSlots,
  startTimeIncrementDurationError: oneToOneData?.startTimeIncrementDurationError,
  oneToDateRange: oneToOneData?.duration,
  oneToOneStartDate: oneToOneData?.startDay,
  oneToOneEndDate: oneToOneData?.endDay,
  blockedDates: oneToOneData?.blockedDates,
  selectedWeeks: oneToOneData?.selectedWeeks,
  calendarActiveView: calendars.currentView,
});
const actions = {
  fetchCalendars: calendarsActions.fetchCalendars,
  addCalendar: calendarsActions.addCalendar,
  getAcсountsForConflictsCheck: calendarsActions.getAcсountsForConflictsCheck,
  getAcсountForSendReminders: calendarsActions.getAcсountForSendReminders,
  removeCalendarFromConflictsCheck: calendarsActions.removeCalendarFromConflictsCheck,
  removeCalendar: calendarsActions.removeCalendar,
  getEditContributionTimes: contributionActions.fetchEditContributionTimes,
  calculateSlots: contributionActions.calculateCreateSlots,
  setSessionDuration: oneToOneDataActions.setSessionDuration,
  setStartTimeIncrementDuration: oneToOneDataActions.setStartTimeIncrementDuration,
  setOneToDateRange: oneToOneDataActions.setDuration,
  setStartDate: oneToOneDataActions.setStartDate,
  setEndDate: oneToOneDataActions.setEndDate,
  setOneToOneTime: oneToOneDataActions?.setOneToOneTime,
  removeBookingTime: oneToOneDataActions?.removeBookingTime,
  addBookingTime: oneToOneDataActions.addBookingTime,
  setBlockedDates: oneToOneDataActions.setBlockedDates,
};

export const OneToOneForm = connect(mapStateToProps, actions)(OneToOneFormComponent);
